// language=GraphQL
export default `
    query rightNavQuery {
        pages (limit:500) {
            total
            items {
                contentTypeId: content__type
                url: localId(transform: "storeUrlToRenderUrl")
                placeInNav(filter: {equals: true}) @skip(if: true)
                orderDefault_f                               
                ... on page_tester {
                    navLabel: shortNavTitle_s(filter: {exists: true}) internal__name
                }
                ... on page_trade__presenter {
                    navLabel: shortNavTitle_s(filter: {exists: true}) internal__name
                }
                ... on page_generic {
                    navLabel: shortNavTitle_s(filter: {exists: true}) internal__name
                    pageIcon_s
                }
            }
        }
    }
`;
