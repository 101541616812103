import React, { Suspense, useEffect, useState } from 'react';
import { neverResource } from '../crafter_site_core/hooks';
import { parseDescriptor, preParseSearchResults } from '@craftercms/content';
import CircularProgressSpinner from '../crafter_site_core/CircularProgressSpinner';
import { createResource } from '../crafter_site_core/utils';
import { map } from 'rxjs/operators';
import { createQuery, search } from '@craftercms/search';
import {ECDetailRenderer} from "./EnterpriseContentDetailView";
import {getTypeForSlug} from "../crafter_site_core/contentTypeMap";

export default function (props) {
  const {
    type = itemTypeFromUrl(props.location.pathname),
    slug = itemSlugFromUrl(props.location.pathname),
  } = props;
  const [itemJson, setItemJson] = useState(neverResource);

  // go get the item
  useEffect(() => {
    const query = createQuery('elasticsearch');
    query.query = {
      query: {
        bool: {
          must: [
            {
              bool: {
                should: [
                  {
                    match: {
                      'content-type': type,
                    }
                  }
                ]
              }
            }
          ],
          should: [
            {
              term: {
                'slug_s': slug
              }
            }
          ]
        }
      },
      from: 0,
      size: 1,
    };
    setItemJson(
      createResource(() =>
        search(query)
          .pipe(
            map((item) => {
              return parseDescriptor(
                preParseSearchResults(item.hits[0]._source)
              );
            })
          )
          .toPromise()
      )
    );
  }, [type, slug]);

  return (
    <Suspense
      fallback={
        <CircularProgressSpinner
          message={'Loading content...'}
          technical={'Enterprise content detail view'}
        />
      }
    >
      <ECDetailRenderer resource={itemJson} />
    </Suspense>
  );
}

export function urlFromItemPathAndSlug(itemPath, slug_s) {
  if (
    itemPath?.startsWith('/site/components/content/') &&
    itemPath?.endsWith('.xml')
  ) {
    const start = '/site/components/content'.length;
    const end = itemPath.indexOf('/',start+1);
    const url = itemPath.substring(start, end+1) + slug_s;
    return url;
  } else {
    return '/404'; // not supported - no hacking!
  }
}

function itemTypeFromUrl(url) {
  // /pretty-type/slug
  const start = 0;
  const end = url.indexOf('/',start+1)
  const typealias = url.substring(start,end)
  return getTypeForSlug(typealias)
}

function itemSlugFromUrl(url) {
  // /pretty-type/slug
  const start = url.indexOf('/', 1) + 1;
  return url.substring(start)
}