import React, { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import {
  useCategoryOptions,
  useIngredientOptions
} from '../../../crafter_site_core/hooks';
import {
  getSearchableTypes,
  getSearchableTypesInOrder
  // labelPlural,
} from '../../../crafter_site_core/contentTypeMap';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import { useGlobalContext } from '../../../crafter_site_core/context';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import ClearAllRoundedIcon from '@material-ui/icons/ClearAllRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
// import HelpRoundedIcon from '@material-ui/icons/HelpRounded';

const useStyles = makeStyles((theme) => ({
  searchresultscontainer: {
    marginTop: 24,
    [theme.breakpoints.down('sm')]: {
      marginTop: 18
    }
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default
  },
  searchbarlg: {
    marginBottom: theme.spacing(2)
  },
  searchbarmd: {
    [theme.breakpoints.up('lg')]: {
      display: 'none'
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  form: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(4),
    position: 'relative'
  },
  input: {
    flexGrow: 1,
    padding: theme.spacing(0, 2),
    width: 700,
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.spacing(2),
    border: '1px solid #6b98d9',
    [theme.breakpoints.down(1592.5)]: {
      width: 600
    },
    [theme.breakpoints.down(1494.5)]: {
      width: 400
    },
    [theme.breakpoints.down(1294)]: {
      width: 250
    },
    [theme.breakpoints.between('sm', 'md')]: {
      width: 573
    },
    [theme.breakpoints.down('sm')]: {
      width: 165
    }
  },
  searchfilter: {
    width: 150,
    '& label': {
      color: theme.palette.text.primary
    },
    [theme.breakpoints.down('sm')]: {
      width: 215
    }
  },
  searchfilterfc: {
    border: '1px solid #6b98d9',
    borderRadius: theme.spacing(1),
    position: 'relative'
  }
}));

export default function (props) {
  const [queryDetails, updateQueryDetails] = useGlobalContext();
  const [q, setQ] = useState(queryDetails.searchTerm);
  const [selectedAll, setSelectedAll] = useState(false);
  const [typeFilterOpen, setTypeFilterOpen] = useState(false);
  const classes = useStyles();
  const theme = useTheme();

  const categories = useCategoryOptions();
  const ingredients = useIngredientOptions();
  const types = getSearchableTypes();
  const sortedTypes = getSearchableTypesInOrder();

  function submitSearchForm(event) {
    event.preventDefault();
    queryDetails.searchTerm = q;
    updateQueryDetails(queryDetails);
  }

  const [searchPrompt] = useState('Start a new search');
  // const [modalOpen, setModalOpen] = useState(false);

  // useEffect(() => {
  //   if (
  //     queryDetails.ingredientKeys != null ||
  //     queryDetails.categoryKeys != null ||
  //     queryDetails.contentTypes != null
  //   ) {
  //     setSearchPrompt('Filter results by keyword');
  //   }
  // }, [
  //   queryDetails.ingredientKeys,
  //   queryDetails.categoryKeys,
  //   queryDetails.contentTypes,
  // ]);

  function selectAll() {
    queryDetails.contentTypes = sortedTypes.map((item) => item.contentType);
    updateQueryDetails(queryDetails);
    setSelectedAll(true);
  }

  function deselectAll() {
    queryDetails.contentTypes = [];
    updateQueryDetails(queryDetails);
    setSelectedAll(false);
  }

  if (categories && ingredients && types) {
    return (
      <Box className={classes.searchresultscontainer}>
        <Box className={classes.searchbarlg}>
          {/* <form onSubmit={submitSearchForm}> */}
          <FormControl>
            <Paper component='form' className={classes.form} elevation={0}>
              <TextField
                id='textSearchField'
                label={searchPrompt}
                value={q || ''}
                onChange={(e) => {
                  setQ(e.target.value);
                }}
                onSubmit={submitSearchForm}
                onKeyPress={(ev) => {
                  if (ev.key === 'Enter') {
                    ev.preventDefault();
                    submitSearchForm(ev);
                  }
                }}
                className={classes.input + ' promotionalcard'}
              />
              <IconButton
                component='span'
                type='submit'
                aria-label='search'
                onClick={submitSearchForm}
                onSubmit={submitSearchForm}
                style={{
                  backgroundColor: theme.palette.secondary.main,
                  position: 'absolute',
                  right: -12,
                  top: 0,
                  zIndex: 1,
                  height: 52,
                  width: 52
                }}
              >
                <SearchIcon style={{ color: theme.palette.common.white }} />
              </IconButton>
            </Paper>
          </FormControl>

          <FormControl
            style={{
              border: '1px solid #6b98d9',
              borderRadius: theme.spacing(2),
              marginRight: theme.spacing(2)
            }}
          >
            <InputLabel id='ingredientMenuLabel'>Ingredient</InputLabel>
            <Select
              labelId='ingredientMenuLabel'
              id='ingredients'
              multiple
              onChange={(e) => {
                queryDetails.ingredientKeys = e.target.value;
                updateQueryDetails(queryDetails);
              }}
              renderValue={(selected) => (selected ? 'View Selected' : '')}
              value={
                queryDetails.ingredientKeys ? queryDetails.ingredientKeys : []
              }
              className={classes.searchfilter}
            >
              {ingredients.map((item) => (
                <MenuItem key={item.key} value={item.key}>
                  <Checkbox
                    checked={
                      queryDetails.ingredientKeys?.indexOf(item.key) > -1
                    }
                  />
                  <ListItemText primary={item.title} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl
            style={{
              border: '1px solid #6b98d9',
              borderRadius: theme.spacing(2),
              marginRight: theme.spacing(2)
            }}
          >
            <InputLabel id='categoryMenuLabel'>Category</InputLabel>
            <Select
              labelId='categoryMenuLabel'
              id='categories'
              multiple
              value={queryDetails.categoryKeys ? queryDetails.categoryKeys : []}
              onChange={(e) => {
                queryDetails.categoryKeys = e.target.value;
                updateQueryDetails(queryDetails);
              }}
              renderValue={(selected) => (selected ? 'View Selected' : '')}
              className={classes.searchfilter}
            >
              {categories.map((item) => (
                <MenuItem key={item.key} value={item.key}>
                  <Checkbox
                    checked={queryDetails.categoryKeys?.indexOf(item.key) > -1}
                  />
                  <ListItemText primary={item.title} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl
            style={{
              border: '2px solid #98D96B',
              borderRadius: theme.spacing(2),
              color: '#98D96B !important'
            }}
          >
            <InputLabel id='typeMenuLabel'>Type</InputLabel>
            <Select
              MenuProps={{
                getContentAnchorEl: () => null
              }}
              onOpen={() => setTypeFilterOpen(true)}
              onClose={() => setTypeFilterOpen(false)}
              labelId='typeMenuLabel'
              id='types'
              multiple
              value={queryDetails.contentTypes ? queryDetails.contentTypes : []}
              onChange={(e) => {
                queryDetails.contentTypes = e.target.value;
                updateQueryDetails(queryDetails);

                console.log(
                  'query from on change: ' + queryDetails.contentTypes
                );
              }}
              renderValue={(selected) => (selected ? 'View Selected' : '')}
              className={classes.searchfilter}
            >
              {sortedTypes.map((item) => (
                <MenuItem key={item.contentType} value={item.contentType}>
                  <Checkbox
                    checked={
                      queryDetails.contentTypes?.indexOf(item.contentType) > -1
                    }
                  />
                  <ListItemText primary={item.title} />
                </MenuItem>
              ))}
            </Select>
            {typeFilterOpen ? (
              <Box position='absolute' top={-24} right={-155} zIndex={1300}>
                <Box
                  width={35}
                  height={35}
                  bgcolor={theme.palette.error.light}
                  borderRadius={18}
                  display='flex'
                  justifyContent='center'
                  alignItems='center'
                >
                  <IconButton aria-label='close-menu'>
                    <CloseRoundedIcon style={{ color: '#fff' }} />
                  </IconButton>
                </Box>
              </Box>
            ) : null}
          </FormControl>
          <FormControl style={{ paddingLeft: 18, paddingRight: 12 }}>
            <Button
              onClick={selectedAll ? deselectAll : selectAll}
              className='button-main'
              variant='contained'
              startIcon={
                selectedAll ? <ClearAllRoundedIcon /> : <DoneAllIcon />
              }
              style={{
                height: '53px',
                backgroundColor: selectedAll
                  ? theme.palette.error.light
                  : theme.palette.secondary.main
              }}
            >
              {selectedAll ? 'Deselect All Types' : 'Select All Types'}
            </Button>
          </FormControl>
          {/* <IconButton onClick={setModalOpen(!modalOpen)}>
            <HelpRoundedIcon color='secondary' />
          </IconButton> */}
          {/* </form> */}
        </Box>
      </Box>
    );
  } else {
    return null;
  }
}
