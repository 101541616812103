import {parseDescriptor, preParseSearchResults} from "@craftercms/content";

export function parseElasticResult(_source, _score, _explanation, _id) {
  let obj = parseDescriptor(preParseSearchResults(_source));
  obj.score = _score
  obj.explanation = mapToArray(simplifyExplanation(_explanation))
  obj.id = _id
  return obj;
}

function simplifyExplanation(ex) {
  let detmap = new Map()
  if (ex?.details) {
    let detarr = ex.details;
    processDetails(detmap, detarr)
  }
  return detmap;
}

function processDetails(map, arr) {
  for (const d of arr) {
    if (d.value > 0) {
      if (d.description === "sum of:") {
        // recurse
        processDetails(map, d.details)
      } else {
        let key = d.value;
        if (d.value < 10) {
          key = "0" + key;
        }
        map.set(key, d.description)
      }
    }
  }
}

function mapToArray(map) {
  let sortedKeys = []
  for (const c of map.keys()) {
    sortedKeys.push(c);
  }
  sortedKeys.sort().reverse();

  let arr = []
  for (const c of sortedKeys) {
    let val = map.get(c);
    if (val.endsWith(") [PerFieldSimilarity], result of:")) {
      val = val.substring(0, val.length - ") [PerFieldSimilarity], result of:".length)
    }
    if (val.startsWith("weight(")) {
      val = val.substring("weight(".length);
    }
    const inidx = val.indexOf(" in ")
    if (inidx > 0) {
      val = val.substring(0, inidx)
    }

    const score = (c+" ").substring(0,6)
    let displayScore = score;
    if (score.startsWith("0")) {
      displayScore = score.substring(1)
    }
    arr.push({
      score: displayScore,
      explanation: val
    })
  }
  return arr;
}
