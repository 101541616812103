import React from 'react';
import Router from './Router';
import { GlobalContextProvider } from './context';
import AppIntl from './AppIntl';
import { crafterConf } from '@craftercms/classes';
import IceSupport from './IceSupport';
import Cookies from 'js-cookie';

const CRAFTERCMS_SITE_COOKIE = Cookies.get('backEndSiteName');
const CRAFTERCMS_PRODUCTION_MODE = CRAFTERCMS_SITE_COOKIE !== undefined;
const CRAFTERCMS_BASE_URL = CRAFTERCMS_PRODUCTION_MODE
  ? window.location.origin
  : process.env.REACT_APP_CRAFTERCMS_BASE_URL;
const CRAFTERCMS_SITE_ID = CRAFTERCMS_PRODUCTION_MODE
  ? CRAFTERCMS_SITE_COOKIE
  : process.env.REACT_APP_CRAFTERCMS_SITE_ID;
export const GRAPHQL_URL =
  CRAFTERCMS_BASE_URL +
  '/api/1/site/graphql' +
  (CRAFTERCMS_PRODUCTION_MODE ? '' : '?crafterSite=' + CRAFTERCMS_SITE_ID);
export const IS_COM_AU = CRAFTERCMS_BASE_URL.endsWith('.com.au');
export const IS_CO_NZ = CRAFTERCMS_BASE_URL.endsWith('.co.nz');

//The perfect audience tag is running on the index.html page
// if (IS_COM_AU) {
//   console.log('AUS Ran');
// } else if (IS_CO_NZ) {
//   console.log('NZ Ran');
// } else {
//   console.log('Neither ran');
// }

export default function CrafterApp() {
  console.log('CRAFTERCMS_PRODUCTION_MODE', CRAFTERCMS_PRODUCTION_MODE);
  console.log('CRAFTERCMS_BASE_URL', CRAFTERCMS_BASE_URL);
  console.log('CRAFTERCMS_SITE_ID', CRAFTERCMS_SITE_ID);
  console.log('GRAPHQL_URL', GRAPHQL_URL);

  crafterConf.configure({
    baseUrl: CRAFTERCMS_BASE_URL,
    site: CRAFTERCMS_SITE_ID,
  });

  return (
    <IceSupport>
      <GlobalContextProvider>
        <AppIntl>
          <Router />
        </AppIntl>
      </GlobalContextProvider>
    </IceSupport>
  );
}
