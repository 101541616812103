import React, { createContext, useContext, useMemo } from 'react';
import { useSpreadState } from './hooks';
import {getDefaultQueryInfo} from "../common/SearchQueryInfo";

export const GlobalContext = createContext();

function useGlobalContext() {
  const context = useContext(GlobalContext);
  if (!context) {
    throw new Error(`useGlobalContext must be used within a GlobalContextProvider`);
  }
  return context;
}

function GlobalContextProvider(props) {
  const [state, setState] = useSpreadState({
    locale: 'en',
    pages: null,
    pagesLoading: false,
    leftNav: null,
    leftNavLoading: false,
    queryDetails: getDefaultQueryInfo(),
    siteInfo: null,
    siteInfoLoading: false,
    elasticQueryCache: {},
    theme: 'light',
    debug: false
  });

  const value = useMemo(() => [state, setState], [state, setState]);

  return <GlobalContext.Provider value={value} {...props} />;
}

export { GlobalContextProvider, useGlobalContext };
