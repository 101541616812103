import React from 'react';
import { NavLink } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import { useSiteInfo } from '../crafter_site_core/hooks';

import Icon from '../utils/icon';

import theme from '../utils/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.dark,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 70
    }
  }
}));

export default function Footer() {
  const classes = useStyles();
  const siteinfo = useSiteInfo();
  let footerBodyText = '';
  let linkedInLink = '';
  let instagramLink = '';
  let facebookLink = '';

  if (siteinfo !== null) {
    footerBodyText = siteinfo.footerBody_html;
    linkedInLink = siteinfo.socialLinkedInLink_s;
    instagramLink = siteinfo.socialInstagramLink_s;
    facebookLink = siteinfo.socialFacebookLink_s;
  }

  return (
    <Box
      className={classes.root + ' no-print'}
      color={theme.palette.common.white}
      pt={4}
    >
      <Container maxWidth='lg'>
        <Box textAlign='center'>
          <Icon name='metagenics-institute-logo-landscape-white' color='#fff' />
        </Box>
        <Box style={{ opacity: '0.8' }} textAlign='center'>
          <div
            className='bodyhtmlraw'
            dangerouslySetInnerHTML={{ __html: footerBodyText }}
          />
        </Box>
        <Box
          display='flex'
          justifyContent='center'
          flexDirection='column'
          width={1}
          pt={6}
        >
          <Box
            display='flex'
            justifyContent='center'
            width={1}
            pb={1}
            textAlign='center'
          >
            <Typography variant='h5'>
              Order Metagenics Practitioner Only Medicines
            </Typography>
          </Box>
          <Box display='flex' alignItems='center' justifyContent='center'>
            <Box pb={2} pr={2}>
              <Button
                color='secondary'
                href='https://www.metagenics.com.au/'
                target='_blank'
                rel='noreferrer noopener'
              >
                In Australia
              </Button>
            </Box>
            <Box pb={2}>
              <Button
                href='https://www.metagenics.co.nz/'
                target='_blank'
                rel='noreferrer noopener'
                color='secondary'
              >
                In New Zealand
              </Button>
            </Box>
          </Box>
        </Box>
        <Box display='flex' justifyContent='center'>
          <Box pt={2}>
            <Button
              color='secondary'
              href='/static-assets/content/pdfs/AccountApplication_AU.pdf'
              target='_blank'
              rel='noreferrer noopener'
              variant='outlined'
            >
              Apply for a Metagenics Account
            </Button>
          </Box>
        </Box>
        <Box py={4} textAlign='center'>
          <IconButton
            href={facebookLink}
            aria-label='facebook social link'
            rel='noreferrer noopener'
          >
            <FacebookIcon color='secondary' />
          </IconButton>
          <IconButton
            href={instagramLink}
            aria-label='instagram social link'
            rel='noreferrer noopener'
          >
            <InstagramIcon color='secondary' />
          </IconButton>
          <IconButton
            href={linkedInLink}
            aria-label='instagram social link'
            rel='noreferrer noopener'
          >
            <LinkedInIcon color='secondary' />
          </IconButton>
          {/* <IconButton href={twitterLink} aria-label='twitter social link' rel='noreferrer noopener'>
            <TwitterIcon color='secondary' />
          </IconButton>
          <IconButton href={youtubeLink} aria-label='youtube social link' rel='noreferrer noopener'>
            <YouTubeIcon color='secondary' />
          </IconButton> */}
        </Box>
        <Box display='flex'>
          <Box pt={2} pr={3}>
            <NavLink to='/about-us'>
              <Button color='secondary'>About Us</Button>
            </NavLink>
          </Box>
          <Box pt={2} pr={3}>
            <NavLink to='/terms-of-use'>
              <Button color='secondary'>Terms of Use</Button>
            </NavLink>
          </Box>
          <Box pt={2} pr={3}>
            <NavLink to='/privacy-policy'>
              <Button color='secondary'>Privacy Policy</Button>
            </NavLink>
          </Box>
        </Box>
        <Box pb={2} textAlign='center' style={{ opacity: '0.8' }}>
          <small>
            &copy; {new Date().getFullYear()} Metagenics Institute. All Rights
            Reserved.
          </small>
        </Box>
      </Container>
      <Box
        bgcolor={theme.palette.primary.darkest}
        display='flex'
        justifyContent='center'
        alignItems='center'
      >
        <Typography>Sponsored by</Typography>
        <Button href='https://www.metagenics.com.au' target='_blank'>
          <Icon
            name='metagenics-logo'
            widthsize={100}
            heightsize={40}
            color='#fff'
          />
        </Button>
      </Box>
    </Box>
  );
}
