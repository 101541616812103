import React from 'react';
import { NavLink } from 'react-router-dom';

import Background from './404/background';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

import Layout from './Layout';
import Icon from '../utils/icon';

const useStyles = makeStyles((theme) => ({
  /* This root needs to be a level lower inorder for the entire page content to be scrollable */
  background: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  content: {
    flexGrow: 1,
  },
  fourzerofourtext: {
    fontSize: '200px',
    fontWeight: 900,
    letterSpacing: 5,
    [theme.breakpoints.down('sm')]: {
      fontSize: '3rem',
    },
  },
  menuhelper: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
}));

export default function NotFound(props) {
  const { detail } = props;
  const classes = useStyles();
  const theme = useTheme();
  return (
    <Layout>
      <Box bgcolor={theme.palette.primary.darkest} className={classes.root}>
        <main className={classes.content}>
          <Box className={classes.background}>
            <Background />
          </Box>
          <Box
            display='flex'
            alignItems='center'
            justifyContent='center'
            height='100vh'
            width={1}
            color={theme.palette.common.white}
            flexDirection='column'
            zIndex={2}
            textAlign='center'
            padding={2}
          >
            <Typography variant='h1' className={classes.fourzerofourtext}>
              404
            </Typography>
            <Typography variant='h4'>
              The page you are looking for cannot be found
            </Typography>
            <Box mt={6}>
              <NavLink to='/search' exact>
                <Button
                  variant='contained'
                  className='button-main'
                  color='secondary'
                  size='large'
                >
                  Back to search
                </Button>
              </NavLink>
            </Box>
            <Typography variant='h6'>
              <p>&nbsp;</p>
              <code>{detail}</code>
            </Typography>
          </Box>
          <Box
            position='fixed'
            top={80}
            right={32}
            color={theme.palette.common.white}
            className={classes.menuhelper}
          >
            <Box display='flex' justifyContent='flex-end' pb={1}>
              <Icon
                name='hand-pointer'
                widthsize={60}
                heightsize={60}
                color='#fff000'
              />
            </Box>
            <Box pr={2}>
              <Typography>Use this menu to quickly get around</Typography>
            </Box>
          </Box>
        </main>
      </Box>
    </Layout>
  );
}
