import React from 'react';

import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

export default function IENotice() {
  const theme = useTheme();
  return (
    <Box
      width={1}
      position='fixed'
      bottom={0}
      left={0}
      right={0}
      textAlign='center'
      zIndex={98}
      bgcolor='#fff'
      py={0.5}
      color={theme.palette.warning.main}
    >
      <Typography variant='h4'>
        Your browser is out of date, please use Chrome, Edge, or Safari for a
        better experience
      </Typography>
    </Box>
  );
}
