import {
  NotFoundPropsNull,
  NotFoundPropsUndefined,
  NotFoundModelNull,
  NotFoundModelUndefined,
  InvalidData,
  NotDeveloped,
} from '../crafter_types/pages/NotFound';
import contentTypeMap from './contentTypeMap';
import React from 'react';
import {usePencil} from "./IceSupport";

export default function ContentType(props) {
  const ice = usePencil(props);
  const Component = locateComponent(props);
  return <Component ice={ice} {...props} />;
}

function locateComponent(props) {
  if (props === null) return NotFoundPropsNull;
  if (props === undefined) return NotFoundPropsUndefined;
  if (props.model === null) return NotFoundModelNull(props);
  if (props.model === undefined) return NotFoundModelUndefined(props);
  if (props.model.craftercms === null) return InvalidData(props);
  if (props.model.craftercms === undefined) return InvalidData(props);
  return (contentTypeMap[props.model.craftercms.contentTypeId] || NotDeveloped);
}
export function WrappedContentType(props) {
  const {
    wrapper,
    wrapper: {
      component: Wrapper = 'div'
    } = {
      component: 'div'
    }
  } = props;
  const ice = usePencil(props);

  // Props that get passed down to the wrapper element
  // these could be things like className or any other
  // HTML attributes or props
  const cleanWrapperProps = { ...wrapper };
  delete cleanWrapperProps.component;

  // Props that get passed down to the ContentType
  const cleanPassDownProps = { ...props };
  delete cleanPassDownProps.wrapper;

  return (
    <Wrapper {...ice} {...cleanWrapperProps}>
      <ContentType {...cleanPassDownProps} />
    </Wrapper>
  );
}
