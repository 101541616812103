import React, { useState } from 'react';

import GoogleMap from './ContactButton/GoogleMap';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { IS_CO_NZ, IS_COM_AU } from '../crafter_site_core/CrafterApp';

import Icon from '../utils/icon';

import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import PhoneRoundedIcon from '@material-ui/icons/PhoneRounded';

import { drawerWidthConst } from '../utils/constants';

const isIE = false || !!document.documentMode;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  contactfab: {
    position: 'fixed',
    bottom: theme.spacing(3),
    right: theme.spacing(3),
    zIndex: 1000,
    boxShadow: 'none !important',
    transition: '0.2s all ease-in-out',
    width: 48,
    height: 48,
    [theme.breakpoints.down('md')]: {
      right: theme.spacing(1.5)
    },
    [theme.breakpoints.down('sm')]: {
      top: theme.spacing(1.5),
      right: theme.spacing(9)
    },
    '&:hover': {
      transform: 'scale(1.1) rotate(360deg)',
      backgroundColor: theme.palette.secondary.main
    }
  },
  dialogbox: {
    color: theme.palette.primary.main,
    zIndex: 1303 + ' !important',
    [theme.breakpoints.up('md')]: {
      marginLeft: drawerWidthConst
    },

    '& .MuiPaper-rounded': {
      borderRadius: theme.spacing(4)
    }
  },
  dialogboxtitle: {
    textAlign: 'center',
    '& h2': {
      fontSize: theme.typography.h2.fontSize,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.h4.fontSize
      }
    }
  },
  linktext: {
    color: theme.palette.secondary.main
  },
  phoneinfoheader: {
    letterSpacing: theme.spacing(1),
    fontWeight: 700
  },
  desktopdialog: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  mobiledialog: {
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  }
}));

export default function ContactButton() {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const classes = useStyles();
  return (
    <>
      <Fab
        className={classes.contactfab + ' no-print'}
        color='secondary'
        onClick={handleClickOpen}
      >
        {isIE ? (
          <PhoneRoundedIcon />
        ) : (
          <Icon
            name='contact-button'
            widthsize={24}
            heightsize={24}
            color='#fff'
          />
        )}
      </Fab>
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        aria-labelledby='form-dialog-title'
        fullWidth={true}
        maxWidth='lg'
        className={classes.dialogbox}
      >
        <DialogTitle id='form-dialog-title' className={classes.dialogboxtitle}>
          Contact Us
        </DialogTitle>
        <Box width={1} display='flex' justifyContent='center' pb={1}>
          <Box
            width={100}
            height={5}
            bgcolor={theme.palette.primary.highlight}
          />
        </Box>
        <DialogContent>
          <Box
            display='flex'
            justifyContent='space-around'
            className={classes.desktopdialog}
          >
            <Box
              display='flex'
              flexDirection='column'
              alignItems='center'
              justifyContent='space-between'
            >
              <Icon name='contact' />
              <Typography
                variant='h1'
                gutterBottom
                className={classes.phoneinfoheader}
              >
                {IS_COM_AU
                  ? '1800 777 648'
                  : IS_CO_NZ
                  ? '0508 22 77 44'
                  : '1800 777 648'}
              </Typography>
              <Typography>7am to 5pm - Monday to Friday</Typography>
            </Box>
            <Box
              display='flex'
              flexDirection='column'
              alignItems='center'
              justifyContent='space-between'
            >
              <Icon name='email' />
              <Typography
                variant='h3'
                gutterBottom
                className={classes.linktext}
              >
                {IS_COM_AU ? (
                  <a href='mailto:info@metagenics.com.au'>
                    info@metagenics.com.au
                  </a>
                ) : IS_CO_NZ ? (
                  <a href='mailto:info@metagenics.co.nz'>
                    info@metagenics.co.nz
                  </a>
                ) : (
                  <a href='mailto:info@metagenics.com.au'>
                    info@metagenics.com.au
                  </a>
                )}
              </Typography>
              <Typography>We typically respond in 24 hours</Typography>
            </Box>
          </Box>
          <Box
            display='flex'
            justifyContent='space-around'
            pt={8}
            px={4}
            alignItems='center'
            className={classes.desktopdialog}
          >
            <Box width={1}>
              <GoogleMap />
            </Box>
            <Box width={1} display='flex' justifyContent='space-around'>
              <Box>
                <Box display='flex' pb={1}>
                  <RoomOutlinedIcon style={{ marginRight: '8px' }} />
                  <Typography variant='h5'>Visit Us</Typography>
                </Box>
                <Box pl={1}>
                  <Typography>
                    Metagenics
                    <br />
                    741 Nudgee Road
                    <br />
                    Northgate
                    <br />
                    Queensland 4013
                  </Typography>
                </Box>
              </Box>
              <Box>
                <Box display='flex' pb={1}>
                  <RoomOutlinedIcon style={{ marginRight: '8px' }} />
                  <Typography variant='h5'>Our Mailbox</Typography>
                </Box>
                <Box pl={1}>
                  <Typography>
                    c/o Metagenics Institute
                    <br />
                    PO Box 675
                    <br />
                    Virginia BC
                    <br />
                    Queensland 4014
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className={classes.mobiledialog}>
            <Box
              display='flex'
              flexDirection='column'
              alignItems='center'
              justifyContent='space-between'
            >
              <Icon name='contact' widthsize={64} heightsize={80} />
              <Typography
                variant='h4'
                gutterBottom
                className={classes.phoneinfoheader}
              >
                {IS_COM_AU
                  ? '1800 777 648'
                  : IS_CO_NZ
                  ? '0508 22 77 44'
                  : '1800 777 648'}
              </Typography>
              <Typography>7am to 5pm - Monday to Friday</Typography>
            </Box>
            <Box
              display='flex'
              flexDirection='column'
              alignItems='center'
              justifyContent='space-between'
              pt={2}
            >
              <Icon name='email' widthsize={64} heightsize={80} />
              <Typography
                variant='h6'
                gutterBottom
                className={classes.linktext}
              >
                {IS_COM_AU ? (
                  <a href='mailto:info@metagenics.com.au'>
                    info@metagenics.com.au
                  </a>
                ) : IS_CO_NZ ? (
                  <a href='mailto:info@metagenics.co.nz'>
                    info@metagenics.co.nz
                  </a>
                ) : (
                  <a href='mailto:info@metagenics.com.au'>
                    info@metagenics.com.au
                  </a>
                )}
              </Typography>
              <Typography>We typically respond in 24 hours</Typography>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <IconButton onClick={handleClose} color='secondary'>
            <HighlightOffIcon />
          </IconButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
