import React, { Component } from 'react';

import Box from '@material-ui/core/Box';

import Icon from '../../utils/icon';

import Particles from 'react-particles-js';

export default class Background extends Component {
  render() {
    return (
      <Box position='absolute' top={0} left={0} right={0} bottom={0} zIndex={0}>
        <Particles
          params={{
            particles: {
              number: {
                value: 60,
                density: {
                  enable: true,
                  value_area: 1500,
                },
              },
              line_linked: {
                enable: true,
                opacity: 0.02,
              },
              move: {
                direction: 'right',
                speed: 0.05,
              },
              size: {
                value: 1,
              },
              opacity: {
                anim: {
                  enable: true,
                  speed: 1,
                  opacity_min: 0.05,
                },
              },
            },
            interactivity: {
              events: {
                onclick: {
                  enable: true,
                  mode: 'push',
                },
              },
              modes: {
                push: {
                  particles_nb: 1,
                },
              },
            },
            retina_detect: true,
          }}
        />
        <Box position='absolute' top='18%' bottom='82%' left='43%' right='57%'>
          <Icon
            name='helix'
            widthsize={400}
            heightsize={400}
            color='rgba(255,255,255,0.15)'
          />
        </Box>
      </Box>
    );
  }
}
