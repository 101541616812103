import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import CrafterApp from './crafter_site_core/CrafterApp';
import './index.scss';
import * as serviceWorker from './serviceWorker';
const TheCrafterApp = () => (
  <React.StrictMode>
    <CrafterApp />
  </React.StrictMode>
);
ReactDOM.render(<TheCrafterApp />, document.getElementById('root'));
serviceWorker.unregister();
