

export default function (queryInfo) {
  // console.log("Creating LeftNavInfo");
  var pfxlength = "/site/components/nav/".length;
  var filenamelength = (queryInfo.guid+".xml").length
  var pathlength = queryInfo.path.length;
  var __sortString = queryInfo.path.slice(pfxlength-1, pathlength-filenamelength) + queryInfo.label.replace(/ /g, "-").replace(/\./g, "-");
  __sortString = __sortString.toLowerCase()
  var __depth = (__sortString.match(/\//g)||[]).length

  // todo: handle this better
  var url = queryInfo.url_s? queryInfo.url_s : '/unspecified-search-page-to-be-ignored';
  // console.log("path......:"+queryInfo.path)
  // console.log("sortstring:"+__sortString)
  // console.log("depth:"+__depth);
  // console.log("LEFT NAV ITEM: "+url,);

  return {
    _guid : queryInfo.guid,
    _path : queryInfo.path,
    _contentTypeId : queryInfo.contentTypeId,
    _label : queryInfo.label,
    __sortString : __sortString,
    __depth: __depth,
    linktext : queryInfo.title_s,
    url : url,
    img : queryInfo.menuImage_s,
  }
}

// {
//   "localId": "/site/components/nav/5-patient-resources/9819b5cb-3c54-98a0-fac0-a8a1a4b64219.xml",
//   "guid": "9819b5cb-3c54-98a0-fac0-a8a1a4b64219",
//   "path": "/site/components/nav/5-patient-resources/9819b5cb-3c54-98a0-fac0-a8a1a4b64219.xml",
//   "contentTypeId": "/component/nav-item",
//   "dateCreated": "2020-04-28T20:37:43.375Z",
//   "dateModified": "2020-05-16T16:34:04.477Z",
//   "label": "4 Patient Videos",
//   "title_s": "Patient Videos",
//   "menuImage_s": null,
//   "url_s": "https://www.metagenics.com.au/institute/patient-videos",
// }
