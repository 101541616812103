import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';

const AnyReactComponent = ({ text }) => (
  <div style={{ width: '200px', display: 'flex', alignItems: 'center' }}>
    <RoomOutlinedIcon />
    {text}
  </div>
);

export default class GoogleMap extends Component {
  static defaultProps = {
    center: {
      lat: -27.396451,
      lng: 153.076768,
    },
    zoom: 17,
  };
  render() {
    return (
      <div
        style={{
          height: '250px',
          width: '100%',
        }}
      >
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyDV_tNSQZhdf0xGakdR-iBQLx1ueogqnfE' }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
        >
          <AnyReactComponent
            lat={-27.39615}
            lng={153.07655}
            text='We Are Here!'
          />
        </GoogleMapReact>
      </div>
    );
  }
}
