import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import ExpandLessRoundedIcon from '@material-ui/icons/ExpandLessRounded';
import { useSiteInfo } from '../crafter_site_core/hooks';
import { useHistory } from 'react-router-dom';

export default function FeaturedContentPopupMobile() {
  const siteinfo = useSiteInfo();
  let ref = useRef(null);
  const [expandedHeight, setExpandedHeight] = useState(0);
  const [hidden, setHidden] = useState(false);
  const [backgroundImageMobile, setBackgroundImageMobile] = useState('');
  const [buttonText, setButtonText] = useState('');
  const [link, setLink] = useState('');
  const [mainColour, setMainColour] = useState('');
  const [secondaryColour, setSecondaryColour] = useState('');
  const [shown, setShown] = useState('');
  const [calloutTitle, setCalloutTitle] = useState('');
  const [contentType, setContentType] = useState('');

  const history = useHistory();

  useEffect(() => {
    const thisRef = ref.current;
    const handleResize = () => {
      setExpandedHeight(thisRef.clientHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => {};
  }, [expandedHeight]);

  useEffect(() => {
    if (siteinfo !== null) {
      if (window.location.pathname !== siteinfo.pageAO_s) {
        setShown(siteinfo.shown_b);
        setBackgroundImageMobile(siteinfo.backgroundImageMobile_s);
        setButtonText(siteinfo.buttonText_s);
        setLink(siteinfo.link_s);
        setMainColour(siteinfo.mainColour_s);
        setSecondaryColour(siteinfo.secondaryColour_s);
        setCalloutTitle(siteinfo.calloutTitle_s);
        setContentType(siteinfo.contentType_s);
      } else {
        setShown(siteinfo.shownAO_b);
        setBackgroundImageMobile(siteinfo.backgroundImageMobileAO_s);
        setButtonText(siteinfo.buttonTextAO_s);
        setLink(siteinfo.linkAO_s);
        setMainColour(siteinfo.mainColourAO_s);
        setSecondaryColour(siteinfo.secondaryColourAO_s);
        setCalloutTitle(siteinfo.calloutTitleAO_s);
        setContentType(siteinfo.contentTypeAO_s);
      }
    }
  }, [siteinfo]);

  useEffect(() => {
    return history.listen((location) => {
      if (siteinfo !== null && location.pathname === siteinfo.pageAO_s) {
        setShown(siteinfo.shownAO_b);
        setBackgroundImageMobile(siteinfo.backgroundImageMobileAO_s);
        setButtonText(siteinfo.buttonTextAO_s);
        setLink(siteinfo.linkAO_s);
        setMainColour(siteinfo.mainColourAO_s);
        setSecondaryColour(siteinfo.secondaryColourAO_s);
        setCalloutTitle(siteinfo.calloutTitleAO_s);
        setContentType(siteinfo.contentTypeAO_s);
      } else if (siteinfo !== null && location.pathname !== siteinfo.pageAO_s) {
        setShown(siteinfo.shown_b);
        setBackgroundImageMobile(siteinfo.backgroundImageMobile_s);
        setButtonText(siteinfo.buttonText_s);
        setLink(siteinfo.link_s);
        setMainColour(siteinfo.mainColour_s);
        setSecondaryColour(siteinfo.secondaryColour_s);
        setCalloutTitle(siteinfo.calloutTitle_s);
        setContentType(siteinfo.contentType_s);
      }
    });
  });

  return (
    <Box display={shown ? 'inherit' : 'none'}>
      <Box display={!hidden ? 'inherit' : 'none'}>
        <Box
          zIndex={98}
          position='fixed'
          bottom={expandedHeight + 70}
          left={0}
          right={0}>
          <Box
            width={1}
            height={40}
            bgcolor={mainColour}
            display='flex'
            alignItems='center'
            onClick={() =>
              setExpandedHeight(
                expandedHeight === 0 ? ref.current.clientHeight : 0
              )
            }>
            <Box
              bgcolor={secondaryColour}
              color='#ffffff'
              height={1}
              display='flex'
              alignItems='center'
              px={1}>
              <Typography variant='overline'>
                <small>{contentType}</small>
              </Typography>
            </Box>
            <Box width={1}>
              <Box display='flex' alignItems='center' width={1}>
                <Box
                  width={0}
                  height={0}
                  pr={1}
                  style={{
                    borderStyle: 'solid',
                    borderWidth: '10px 0 10px 10px',
                    borderColor: `transparent transparent transparent ${secondaryColour}`,
                  }}></Box>
                <Box flexGrow={1} color='#fff'>
                  <Typography variant='body2' style={{ lineHeight: 1.15 }}>
                    {calloutTitle}
                  </Typography>
                </Box>
                {expandedHeight === 0 ? (
                  <ExpandLessRoundedIcon
                    style={{ marginRight: 8, color: '#fff' }}
                  />
                ) : (
                  <CancelRoundedIcon
                    style={{ marginRight: 8, color: '#fff' }}
                    onClick={() => setHidden(true)}
                  />
                )}
              </Box>
            </Box>
          </Box>
          <Box
            height={expandedHeight === 0 ? '60px' : '0px'}
            display={expandedHeight === 0 ? 'flex' : 'none'}
            alignItems='center'
            pl={1.5}
            style={{
              background: `url('${backgroundImageMobile}')`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
            }}>
            {link.includes('https://') ? (
              <a href={link} target='_blank' rel='noopener noreferrer'>
                <Button
                  variant='contained'
                  color='secondary'
                  className='button-main'
                  endIcon={<ChevronRightRoundedIcon />}
                  onClick={() => setHidden(true)}>
                  {buttonText}
                </Button>
              </a>
            ) : (
              <NavLink to={link} exact>
                <Button
                  variant='contained'
                  color='secondary'
                  className='button-main'
                  endIcon={<ChevronRightRoundedIcon />}
                  onClick={() => setHidden(true)}>
                  {buttonText}
                </Button>
              </NavLink>
            )}
          </Box>
        </Box>
        <Box
          position='fixed'
          bottom={70}
          width={1}
          height={expandedHeight}
          bgcolor='#84C778'
          zIndex={98}>
          <img
            src={backgroundImageMobile}
            alt='featured content'
            width='100%'
            height='auto'
            ref={ref}
          />
          <Box position='relative'>
            <Box position='absolute' bottom={14} left={12}>
              {link.includes('https://') ? (
                <a href={link} target='_blank' rel='noopener noreferrer'>
                  <Button
                    variant='contained'
                    color='secondary'
                    className='button-main'
                    endIcon={<ChevronRightRoundedIcon />}
                    onClick={() => {
                      setExpandedHeight(
                        expandedHeight === 0 ? ref.current.clientHeight : 0
                      );
                      setHidden(true);
                    }}>
                    {buttonText}
                  </Button>
                </a>
              ) : (
                <NavLink to={link} exact>
                  <Button
                    variant='contained'
                    color='secondary'
                    className='button-main'
                    endIcon={<ChevronRightRoundedIcon />}
                    onClick={() => {
                      setExpandedHeight(
                        expandedHeight === 0 ? ref.current.clientHeight : 0
                      );
                      setHidden(true);
                    }}>
                    {buttonText}
                  </Button>
                </NavLink>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
