import React, {useEffect, useState} from 'react';

import CircularProgressSpinner from './CircularProgressSpinner';
import ContentType from './ContentType';
import {parseDescriptor, preParseSearchResults} from '@craftercms/content';
import {createQuery, search} from "@craftercms/search";
import NotFound from "../common/404";
import {useGlobalContext} from "./context";

export default function DynamicRoute(props) {

  const {match} = props;
  const url = match.url;
  const [state, setState] = useState(null);
  const [done, setDone] = useState(false);
  const [{debug}] = useGlobalContext();

  useEffect(() => {
    const query = createQuery('elasticsearch');
    query.query = {
      query: {
        bool: {
          must: [
            {
              term: {
                localId: pagePathFromUrl(url),
              }
            }
          ]
        }
      },
      from: 0,
      size: 1,
    }
    search(query).subscribe((res) => {
      //console.log("Elastic query ran: "+JSON.stringify(query, null, 2) + " from Elastic: "+JSON.stringify(res, null, 2))
      if (res.total.value > 0) {
        const obj = parseDescriptor(
          preParseSearchResults(res.hits[0]._source)
        )
        setState({
          model: obj
        })
      } else {
        // no results found
        if (debug) {
          console.log("DynamicRoute found no results when attempting to load "+pagePathFromUrl(url)+" via elastic search. Query:",query)
        }
      }
      setDone(true)
    })
    return () => {};
  }, [url, debug]);

  if (done) {

    if (state !== undefined && state !== null) {
      return <ContentType {...state} {...props} />
    } else {
      return <NotFound detail='dr'/>
    }

  } else {
    // return <CircularProgressSpinner message='not done'/>
    return <CircularProgressSpinner message='Loading content...' technical={'Dynamic route loading page'}/>
  }
}

function pagePathFromUrl(url) {
  return url === '/' ? '/site/website/index.xml' : '/site/website' + url + '/index.xml';
}
