// language=GraphQL
export default `
    query ingredientsQuery {
        component_ingredient(limit: 500, sortBy: "taxItemName_s", sortOrder: ASC) {
            total
            items {
                key: localId
                name: taxItemName_s
            }
        }
    }

`