import React, { useState, useEffect, useMemo, useRef } from "react";
import { useInfiniteScroll } from "react-infinite-scroll-hook";
// import { motion } from 'framer-motion';
import SearchResultIntermediary from "./SearchResultIntermediary";
// import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
// import List from '@material-ui/core/List';
// import ListItem from '@material-ui/core/ListItem';
// import ListItemText from '@material-ui/core/ListItemText';
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { filterType } from "../../../common/SearchQueryInfo";
import { useGlobalContext } from "../../../crafter_site_core/context";
import { getSearchableTypeCodes } from "../../../crafter_site_core/contentTypeMap";
// import { drawerWidthConst } from '../../../utils/constants';
// import noSearchResultImg from '../../../images/no-search-result.png';

// const useStyles = makeStyles((theme) => ({
//   nosearchimage: {
//     position: 'absolute',
//     right: -100,
//     [theme.breakpoints.down('sm')]: {
//       display: 'none',
//     },
//   },
//   noresultcontainer: {
//     height: '65vh',
//     [theme.breakpoints.down('sm')]: {
//       height: '100%',
//     },
//   },
// }));

export default function SearchResultSection(props) {
  // const classes = useStyles();
  const matchesXl = useMediaQuery("(min-width:1775.55px)");
  const matchesLg = useMediaQuery(
    "(min-width:1335.55px) and (max-width:1775.5px)"
  );
  const matchesMd = useMediaQuery(
    "(min-width:1024.55px) and (max-width:1335.5px)"
  );
  const matchesSm = useMediaQuery(
    "(min-width:640.55px) and (max-width:1024.5px)"
  );
  const matchesXs = useMediaQuery(
    "(min-width:377.55px) and (max-width:640.5px)"
  );
  const matchesXxs = useMediaQuery("(max-width:377.5px)");
  const [cardAmount, setCardAmount] = useState(6);

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    if (matchesXl && cardAmount !== 6) {
      setCardAmount(6);
    }

    if (matchesLg && cardAmount !== 5) {
      setCardAmount(5);
    }

    if (matchesMd && cardAmount !== 4) {
      setCardAmount(4);
    }

    if (matchesSm && cardAmount !== 3) {
      setCardAmount(3);
    }

    if (matchesXs && cardAmount !== 2) {
      setCardAmount(2);
    }

    if (matchesXxs && cardAmount !== 1) {
      setCardAmount(1);
    }
  }, [
    matchesXl,
    cardAmount,
    matchesLg,
    matchesMd,
    matchesSm,
    matchesXs,
    matchesXxs
  ]);

  const [queryDetails] = useGlobalContext();

  const byType = [];
  for (const st of getSearchableTypeCodes()) {
    if (
      queryDetails?.contentTypes?.length === undefined ||
      queryDetails?.contentTypes?.length === 0 ||
      queryDetails?.contentTypes?.includes(st)
    ) {
      byType.push(filterType(st, queryDetails));
    }
  }

  const [loading, setLoading] = useState(false);
  const [numToShow, setNumToShow] = useState(3); // by default, just show 2 content types worth of results. add more if needed
  const [hasMore, setHasMore] = useState(true);
  // const [amountShowing, setAmountShowing] = useState(0);

  const NoMoreToShow = () => {
    // if (amountShowing === 1) {
    return (
      <Box>
        <Container maxWidth='lg'>
          <Box
            width={1}
            display='flex'
            justifyContent='center'
            alignItems='center'
            height={450}
            flexDirection='column'
          >
            <Typography variant='h5' style={{ marginBottom: 16 }}>
              No more results to load, to refine your search please use the
              filters above.
            </Typography>
            <Button color='secondary' variant='contained' onClick={scrollTop}>
              Scroll to top
            </Button>
          </Box>
        </Container>
      </Box>
    );
    // }
    // else {
    //   return (
    //     <Box
    //       width={1}
    //       display='flex'
    //       alignItems='center'
    //       mt={4}
    //       flexDirection='column'
    //       ml={{ drawerWidthConst }}
    //       position='relative'
    //       className={classes.noresultcontainer}
    //     >
    //       <Box
    //         width={1}
    //         height={75}
    //         bgcolor='#fff'
    //         color='primary'
    //         display='flex'
    //         alignItems='center'
    //         justifyContent='center'
    //         mt={4}
    //       >
    //         <Box width='80vw'>
    //           <Typography variant='h2'>Start a New Search</Typography>
    //         </Box>
    //       </Box>
    //       <Box width='80vw' py={4}>
    //         <Typography paragraph>
    //           Here are some handy tips to find the content you need:
    //         </Typography>
    //         <List dense>
    //           <ListItem>
    //             <ListItemText primary='Enter text in the search bar and make sure to check your spelling' />
    //           </ListItem>

    //           <ListItem>
    //             <ListItemText primary='Try different or more general keywords and tags if you do not find what you are looking for' />
    //           </ListItem>

    //           <ListItem>
    //             <ListItemText primary='Use the Ingredient, Category, and Type filters to return more specific results' />
    //           </ListItem>
    //         </List>
    //       </Box>
    //       <motion.div
    //         className={classes.nosearchimage}
    //         animate={{ x: -100 }}
    //         transition={{ delay: 2, ease: 'easeInOut', duration: 0.75 }}
    //       >
    //         <img src={noSearchResultImg} alt='no search result' />
    //       </motion.div>
    //     </Box>
    //   );
    // }
  };

  const ScrollToShowMore = () => {
    return (
      <Box>
        <Container maxWidth='lg'>
          <Box
            width={1}
            display='flex'
            justifyContent='center'
            alignItems='center'
            height={450}
          >
            <Typography variant='h5'>
              Please wait while more results load...
            </Typography>
          </Box>
        </Container>
      </Box>
    );
  };

  function handleLoadMore() {
    setLoading(true);
    const byTypeLen = byType?.length;
    if (numToShow === byTypeLen) {
    } else {
      setNumToShow(numToShow + 2);
    }
    setLoading(false);
    setHasMore(numToShow < byTypeLen);
  }

  useEffect(() => {
    setHasMore(numToShow < byType?.length);
  }, [byType, numToShow]);

  const infiniteRef = useInfiniteScroll({
    loading,
    hasNextPage: hasMore,
    onLoadMore: handleLoadMore,
    scrollContainer: "window"
  });

  const typesToShowNow = useMemo(() => byType?.slice(0, numToShow), [
    numToShow,
    byType
  ]);

  // let element = document.getElementById('result-row');
  const heightRef = useRef();

  // useEffect(() => {
  //   if (typeof element != 'undefined' && element != null) {
  //     setAmountShowing(1);
  //   } else {
  //     setAmountShowing(0);
  //   }
  // });

  // useEffect(() => {
  //   if (heightRef.current.clientHeight <= 0) {
  //     setAmountShowing(0);
  //   } else {
  //     setAmountShowing(1);
  //   }
  //   // console.log('Height: ' + heightRef.current.clientHeight);
  // }, [heightRef]);

  return (
    <Box mt={4}>
      <span ref={infiniteRef}>
        {typesToShowNow?.map((q, index) => (
          <div key={index} id='result-row' ref={heightRef}>
            <Container maxWidth='xl'>
              <SearchResultIntermediary qry={q} from={0} size={cardAmount} />
            </Container>
          </div>
        ))}
      </span>
      {byType?.length > numToShow ? <ScrollToShowMore /> : <NoMoreToShow />}
      {/*{console.log(byType?.length > numToShow)}*/}
    </Box>
  );
}
