export function getDefaultQueryInfo() {
  return createSearchQueryObj([],[],[],[],[],'');
}
/**
 * Extract the query info from the curatedSearchInfo object. Note that
 * the curated search info cannot set the arbitrary query term, but it
 * can set tags.
 * @param curatedSearchInfo
 * @returns {{categoryKeys: (*|[]), ingredientKeys: (*|[]), searchTerm: *, sortInfo: *, tagKeys: (*|[]), contentTypes: (*|[])}}
 */
export function getQueryFromCurated(curatedSearchInfo) {
  const types = curatedSearchInfo?.types_o?.map((t, index) => { return t.key; });
  const categories = curatedSearchInfo.categories_o?.map((item) => {return item.craftercms.path;});
  const ingredients = curatedSearchInfo.ingredients_o?.map((item) => {return item.craftercms.path;})
  const tags = curatedSearchInfo.tags_o?.map((item) => {return item.craftercms.path;})
  const sort = [];

  if (curatedSearchInfo?.sortInfo_o) {
    for (const sc of curatedSearchInfo?.sortInfo_o) {
      if (sc.sortByCriteria_s === 'd') {
        // sort by date. Could be various fields so try to be smart and guess the one they really mean.
        sort.push({
          datePublished_dt: {
            order: sc.sortByOrder_s
          }
        })
        sort.push({
          seminarDate_dt: {
            order: sc.sortByOrder_s
          }
        })
        sort.push({
          webinarDate_dt: {
            order: sc.sortByOrder_s
          }
        })
        sort.push(
          {
            congressDate_dt: {
              order: sc.sortByOrder_s
            }
          }
        )
        sort.push(
          {
            lastModifiedDate_dt: {
              order: sc.sortByOrder_s
            }
          }
        )
      }

      if (sc.sortByCriteria_s === 'a') {
        sort.push({
          title_s: {
            order: sc.sortByOrder_s
          }
        })
      }

      if (sc.sortByCriteria_s === 'r') {
        sort.push("_score");
      }
    }
  }
  const q = null
  return createSearchQueryObj(types, ingredients, categories, tags, sort, q);
}

/**
 * Helper utility function
 * @param typeCodes
 * @param ingredientKeys
 * @param categoryKeys
 * @param tagKeys
 * @param sortInfo
 * @param searchTerm
 * @returns {{categoryKeys: (*|[]), ingredientKeys: (*|[]), searchTerm: *, sortInfo: *, tagKeys: (*|[]), contentTypes: (*|[])}}
 */
function createSearchQueryObj(typeCodes, ingredientKeys, categoryKeys, tagKeys, sortInfo, searchTerm) {
  return {
    contentTypes: typeCodes ? typeCodes : [],
    ingredientKeys: ingredientKeys ? ingredientKeys : [],
    categoryKeys: categoryKeys ? categoryKeys : [],
    tagKeys: tagKeys ? tagKeys : [],
    sortInfo: sortInfo ? sortInfo : [],
    searchTerm: searchTerm
  };
}

/**
 * Remove all types from the query info except the one specified.
 * @param type
 * @param queryInfo
 * @returns {{categoryKeys: [], ingredientKeys: [], searchTerm: *, sortInfo: *, tagKeys: [], contentTypes: [*]}}
 */
export function filterType(type, queryInfo) {
  return {
    contentTypes: [type],
    ingredientKeys: queryInfo.ingredientKeys,
    categoryKeys: queryInfo.categoryKeys,
    tagKeys: queryInfo.tagKeys,
    sortInfo: queryInfo.sortInfo,
    searchTerm: queryInfo.searchTerm
  };
}
export function isBlankQuery(queryInfo) {
  if (queryInfo === undefined) return true;
  if (queryInfo === null) return true;
  return queryInfo.contentTypes.length === 0
    && queryInfo.ingredientKeys.length === 0
    && queryInfo.categoryKeys.length === 0
    && queryInfo.tagKeys.length === 0
    && queryInfo.sortInfo.length === 0
    && queryInfo.searchTerm === null;
}
