import React from 'react';

import { motion } from 'framer-motion';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useGlobalContext } from './context';

export default function CircularProgressSpinner(props) {
  const { message, technical } = props;
  const [{ debug }] = useGlobalContext();

  return (
    <>
      <Box
        display='flex'
        alignItems='center'
        justifyContent='center'
        height='500px'
        flexDirection='column'
        width={1}
      >
        <Box className='circular-progress-rotating' width={75} height={75}>
          <motion.svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 75 75'
            className='item circular-progress-spinner--graphic'
          >
            <path
              fill='#4F6A88'
              d='M13.31,62.42c1.52,0,2.82,0.91,3.34,2.24l2.71-0.5v-0.08c0-1.36,0.6-2.57,1.55-3.39l-3.31-5.38
		c-0.54,0.23-1.13,0.36-1.74,0.36c-2.55,0-4.6-2.06-4.6-4.59c0-2.54,2.05-4.6,4.6-4.6c2.52,0,4.58,2.07,4.58,4.6
		c0,1.39-0.61,2.63-1.58,3.46l3.29,5.36c0.53-0.22,1.11-0.34,1.7-0.34c0.22,0,0.44,0.03,0.65,0.06l1.9-5.96
		c-1.84-0.88-3.13-2.76-3.13-4.93c0-3.02,2.44-5.46,5.46-5.46c1.48,0,2.82,0.58,3.81,1.53l11.44-9.01c-0.57-0.9-0.92-1.96-1-3.1
		l-2.68-0.09c-0.53,4.34-4.23,7.73-8.73,7.73c-4.85,0-8.78-3.94-8.78-8.8c0-4.85,3.93-8.78,8.78-8.78c4.73,0,8.58,3.71,8.78,8.38
		l2.67,0.1c0.29-1.99,1.45-3.7,3.08-4.72l-3.83-7.9c-0.27,0.07-0.55,0.11-0.85,0.11c-1.95,0-3.51-1.57-3.51-3.51
		c0-1.95,1.57-3.53,3.51-3.53c1.1,0,2.08,0.51,2.73,1.3l6-3.07c-0.27-0.71-0.42-1.49-0.42-2.31c0-1.89,0.8-3.59,2.08-4.78
		C47.42,1.01,42.58,0,37.5,0C16.79,0,0,16.78,0,37.49c0,10.17,4.06,19.39,10.63,26.15C11.29,62.89,12.24,62.42,13.31,62.42z'
            />
            <path
              fill='#4F6A88'
              d='M62.52,9.57c-0.83,2.66-3.29,4.59-6.23,4.59c-2.28,0-4.28-1.16-5.45-2.93l-6,3.08
		c0.08,0.3,0.12,0.61,0.12,0.92c0,0.15-0.01,0.29-0.03,0.45l5.87,2.07c0.78-1.43,2.28-2.41,4.03-2.41c2.54,0,4.59,2.05,4.59,4.59
		c0,2.53-2.05,4.6-4.59,4.6c-2.53,0-4.59-2.07-4.59-4.6c0-0.28,0.03-0.55,0.08-0.81l-5.87-2.06c-0.22,0.37-0.51,0.69-0.84,0.94
		l3.83,7.89c0.71-0.25,1.47-0.39,2.27-0.39c3.71,0,6.73,3.01,6.73,6.73c0,3.72-3.02,6.74-6.73,6.74c-1.89,0-3.59-0.78-4.82-2.02
		l-11.45,9.02c0.49,0.8,0.76,1.75,0.76,2.77c0,0.58-0.08,1.14-0.26,1.65l2.35,1.13c1.01-1.56,2.77-2.58,4.76-2.58
		c3.14,0,5.67,2.54,5.67,5.65c0,3.13-2.53,5.67-5.67,5.67c-3.13,0-5.67-2.53-5.67-5.67c0-0.61,0.1-1.2,0.28-1.76l-2.36-1.11
		c-0.98,1.5-2.65,2.48-4.57,2.48c-0.32,0-0.63-0.02-0.95-0.09l-1.89,5.96c1.46,0.75,2.45,2.26,2.45,4.01c0,2.47-2,4.47-4.49,4.47
		c-1.94,0-3.58-1.23-4.22-2.96l-2.72,0.5c-0.02,0.85-0.33,1.6-0.81,2.2C22.18,72.52,29.55,75,37.5,75C58.21,75,75,58.19,75,37.49
		C75,26.4,70.18,16.43,62.52,9.57z'
            />
          </motion.svg>
        </Box>
        <Box mt={2}>
          <Typography paragraph className='circular-progress-spinner--message'>
            {message && message}
          </Typography>
          <Typography paragraph className='circular-progress-spinner--message2'>
            {debug && 'DEBUG: [' + technical + ']'}
          </Typography>
        </Box>
      </Box>
    </>
  );
}
