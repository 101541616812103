import {useEffect} from "react";

export default (history, timeout) => {
  useEffect(() => {
    if (!history) {
      return
    }

    const location = history.location;
    if (typeof location.hash !== 'string') {
      return;
    }

    const elementId = location.hash.slice(1);
    if (!elementId) {
      return;
    }

    const createScrollToElement = (id) => {
      return () => {
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
          reset();
          return true;
        }
        return false;
      };
    };

    const scrollToElement = createScrollToElement(elementId);

    let lt1 = setTimeout(() => { scrollToElement(elementId) }, timeout/8);
    let lt2 = setTimeout(() => { scrollToElement(elementId) }, timeout/4);
    let lt3 = setTimeout(() => { scrollToElement(elementId) }, timeout/2);
    let lt4 = setTimeout(() => { scrollToElement(elementId) }, timeout);

    const reset = () => {
      clearTimeout(lt1)
      clearTimeout(lt2)
      clearTimeout(lt3)
      clearTimeout(lt4)
      lt1 = null
      lt2 = null
      lt3 = null
      lt4 = null
    }
  }, [history, timeout]);
};