/*
 * Copyright (C) 2007-2020 Crafter Software Corporation. All Rights Reserved.
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License version 3 as published by
 * the Free Software Foundation.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import {lazy} from 'react';

export default {
	// taxonomy & inheritance
	'/component/level-descriptor': lazy(() =>
		import('../crafter_types/web_components/LevelDescriptor')
	),
	// generic content
	'/component/disclaimer': lazy(() =>
		import('../crafter_types/generic_content/Disclaimer')
	),
	'/component/address': lazy(() =>
		import('../crafter_types/generic_content/Address')
	),
	'/component/congress': lazy(() =>
		import('../crafter_types/generic_content/Congress')
	),
	'/component/patient-brochure': lazy(() =>
		import('../crafter_types/generic_content/PatientBrochure')
	),
	'/component/metagenics-update': lazy(() =>
		import('../crafter_types/generic_content/MetagenicsUpdate')
	),
	'/component/seminar': lazy(() =>
		import('../crafter_types/generic_content/Seminar')
	),
	'/component/case-study': lazy(() =>
		import('../crafter_types/generic_content/CaseStudy')
	),
	'/component/protocol': lazy(() =>
		import('../crafter_types/generic_content/Protocol')
	),
	'/component/metaproduct': lazy(() =>
		import('../crafter_types/generic_content/Product')
	),
	'/component/tech-data': lazy(() =>
		import('../crafter_types/generic_content/TechData')
	),
	'/component/infographic': lazy(() =>
		import('../crafter_types/generic_content/Infographic')
	),
	'/component/blog-post': lazy(() =>
		import('../crafter_types/generic_content/BlogPost')
	),
	'/component/metagenics-update-article': lazy(() =>
		import('../crafter_types/generic_content/MetagenicsArticle')
	),
	'/component/webinar': lazy(() =>
		import('../crafter_types/generic_content/Webinar')
	),
	'/component/podcast': lazy(() =>
		import('../crafter_types/generic_content/Podcast')
	),
	'/component/pdf': lazy(() => import('../crafter_types/generic_content/PDF')),

	'/component/diet': lazy(() => import('../crafter_types/generic_content/PDF')),

	'/component/patient-booklet': lazy(() =>
		import('../crafter_types/generic_content/PatientBooklet')
	),
	'/component/patient-handout': lazy(() =>
		import('../crafter_types/generic_content/PDF')
	),
	'/component/patientnewsletter': lazy(() =>
		import('../crafter_types/generic_content/PDF')
	),
	'/component/recipe': lazy(() =>
		import('../crafter_types/generic_content/PDF')
	),
	'/component/questionnaire': lazy(() =>
		import('../crafter_types/generic_content/PDF')
	),
	'/component/scientific-poster': lazy(() =>
		import('../crafter_types/generic_content/PDF')
	),
	'/component/shopping-list': lazy(() =>
		import('../crafter_types/generic_content/PDF')
	),
	'/component/clinicalguide': lazy(() =>
		import('../crafter_types/generic_content/PDF')
	),
	'/component/practitioner-resource': lazy(() =>
		import('../crafter_types/generic_content/PractitonerResource')
	),
	'/component/video': lazy(() =>
		import('../crafter_types/generic_content/Video')
	),
	'/component/patient-video': lazy(() =>
		import('../crafter_types/generic_content/Video')
	),
	'/component/functional-foundations': lazy(() =>
		import('../crafter_types/generic_content/Video')
	),
	'/component/upcomingeducation': lazy(() =>
		import('../crafter_types/generic_content/UpcomingEducation')
	),

	// pages
	'/page/generic': lazy(() => import('../crafter_types/pages/Generic')),
	'/page/trade-presenter': lazy(() =>
		import('../crafter_types/pages/TradePresenter')
	),
	'/page/tester': lazy(() => import('../crafter_types/pages/Tester')),

	// supporting web content
	'/component/nav-item': lazy(() =>
		import('../crafter_types/web_components/NavItem')
	),
	'/component/three-up-adapter': lazy(() =>
		import('../crafter_types/web_components/ThreeUpAdapter')
	),
	'/component/two-up-adapter': lazy(() =>
		import('../crafter_types/web_components/TwoUpAdapter')
	),
	'/component/one-up-adapter': lazy(() =>
		import('../crafter_types/web_components/OneUpAdapter')
	),
	'/component/latest-content-marker': lazy(() =>
		import('../crafter_types/web_components/LatestContent')
	),
	'/component/newsletter-signup-marker': lazy(() =>
		import('../crafter_types/web_components/NewsletterSignup')
	),
	'/component/sharpspringembed': lazy(() =>
		import('../crafter_types/web_components/SharpSpringEmbed')
	),
	'/component/search-bar-marker': lazy(() =>
		import('../crafter_types/web_components/SearchBar')
	),
	'/component/search-result-marker': lazy(() =>
		import('../crafter_types/pages/SearchResult/SearchResultSection')
	),
	'/component/basic-card': lazy(() =>
		import('../crafter_types/web_components/BasicCard')
	),
	'/component/card-with-cta': lazy(() =>
		import('../crafter_types/web_components/CardWithCta')
	),
	'/component/accordion': lazy(() =>
		import('../crafter_types/web_components/AccordionSection')
	),
	'/component/text-block': lazy(() =>
		import('../crafter_types/web_components/TextBlock')
	),
	'/component/slider': lazy(() =>
		import('../crafter_types/web_components/Slider')
	),
	'/component/image-hero': lazy(() =>
		import('../crafter_types/web_components/ImageHero')
	),
	'/component/threetabslider': lazy(() =>
		import('../crafter_types/web_components/ThreeTabSlider')
	),
	'/component/fourcardcallout': lazy(() =>
		import('../crafter_types/web_components/FourCardCallout')
	),
	'/component/quoteslider': lazy(() =>
		import('../crafter_types/web_components/QuoteSlider')
	),
	'/component/3wrapgrid': lazy(() =>
		import('../crafter_types/web_components/ThreeWrapGrid')
	),
	'/component/fullwidthtwocol': lazy(() =>
		import('../crafter_types/web_components/FullWidthTwoCol')
	),
	'/component/textblockwithcta': lazy(() =>
		import('../crafter_types/web_components/TextBlockWithCta')
	),
	'/component/animatedbody': lazy(() =>
		import('../crafter_types/web_components/BodyAnimOnEnter')
	),
	'/component/announcementcard': lazy(() =>
		import('../crafter_types/web_components/AnnouncementCard')
	),
	'/component/frontpageheader': lazy(() =>
		import('../crafter_types/web_components/FrontPageHeader')
	),
	'/component/congressheader': lazy(() =>
		import('../crafter_types/web_components/Congress/Header')
	),
	'/component/anchor-menu': lazy(() =>
		import('../crafter_types/web_components/Congress/AnchorMenu')
	),
	'/component/congress-intro': lazy(() =>
		import('../crafter_types/web_components/Congress/Intro')
	),
	'/component/introalt': lazy(() =>
		import('../crafter_types/web_components/Congress/IntroAlt')
	),
	'/component/fullheighttext': lazy(() =>
		import('../crafter_types/web_components/FullHeightText')
	),
	'/component/full-width-banner': lazy(() =>
		import('../crafter_types/web_components/Congress/FullWidthBanner')
	),
	'/component/iframeembed': lazy(() =>
		import('../crafter_types/web_components/IframeEmbed')
	),
	'/component/congressrego': lazy(() =>
		import('../crafter_types/web_components/CongressRego')
	),
	'/component/congressregoalt': lazy(() =>
		import('../crafter_types/web_components/CongressRegoAlt')
	),
	'/component/speaker-agenda': lazy(() =>
		import('../crafter_types/web_components/Congress/SpeakerAgenda')
	),
	'/component/googlemapscustom': lazy(() =>
		import('../crafter_types/web_components/GoogleMapsCustom')
	),
	'/component/seminarheader': lazy(() =>
		import('../crafter_types/web_components/Seminar/SeminarHeader')
	),
	'/component/seminarlocations': lazy(() =>
		import('../crafter_types/web_components/Seminar/SeminarLocations')
	),
	'/component/seminaragenda': lazy(() =>
		import('../crafter_types/web_components/Seminar/SeminarAgenda')
	),
	'/component/seminarspeakers': lazy(() =>
		import('../crafter_types/web_components/Seminar/SeminarSpeakers')
	),
	'/component/videobackground': lazy(() =>
		import('../crafter_types/web_components/VideoBackground')
	),
	'/component/videopopouts': lazy(() =>
		import('../crafter_types/web_components/VideoPopouts')
	),
	'/component/threecardhighlight': lazy(() =>
		import('../crafter_types/web_components/CongressThreeCards')
	),
	'/component/tradeadvertcontact': lazy(() =>
		import('../crafter_types/web_components/TradeAdvertContactMarker')
	),
	'/component/tradeadvertheader': lazy(() =>
		import('../crafter_types/web_components/TradeAdvertHeader')
	),
	'/component/tradeadvertproductslist': lazy(() =>
		import('../crafter_types/web_components/TradeAdvertProductsList')
	),
	'/component/tradeadvertquote': lazy(() =>
		import('../crafter_types/web_components/TradeAdvertQuote')
	),
	'/component/tradeadverttwocol': lazy(() =>
		import('../crafter_types/web_components/TradeAdvertTwoCol')
	),
};

export function labelSingular() {
	return {
		// taxonomy & inheritance
		'/component/level-descriptor': 'Section Defaults',

		// generic content
		'/component/disclaimer': 'Disclaimer',
		'/component/address': 'Address',
		'/component/congress': 'Congress',
		'/component/patient-brochure': 'Patient Brochure',
		'/component/metagenics-update': 'Metagenics Update Issue',
		'/component/seminar': 'Seminar',
		'/component/case-study': 'Case Study',
		'/component/protocol': 'Protocol',
		'/component/metaproduct': 'Product',
		'/component/tech-data': 'Technical Data Sheet',
		'/component/infographic': 'Infographic',
		'/component/blog-post': 'Blog Post',
		'/component/metagenics-update-article': 'Article',
		'/component/webinar': 'Webinar',
		'/component/podcast': 'Podcast',
		'/component/pdf': 'PDF',
		'/component/diet': 'Diet',
		'/component/patient-booklet': 'Patient Booklet',
		'/component/patient-handout': 'Patient Handout',
		'/component/patientnewsletter': 'Patient Newsletter',
		'/component/practitioner-resource': 'Practitioner Resource',
		'/component/recipe': 'Recipe',
		'/component/questionnaire': 'Questionnaire',
		'/component/clinicalguide': 'Clinical Guide',
		'/component/scientific-poster': 'Scientific Poster',
		'/component/shopping-list': 'Shopping List',
		'/component/video': 'Video',
		'/component/functional-foundations': 'Functional Foundations',
		'/component/patient-video': 'Patient Video',
		'/component/upcomingeducation': 'Education Calendar',

		// pages
		'/page/generic': 'Landing Page',
		'/page/trade-presenter': 'Practitioner Guide',
		'/page/tester': 'Test Page',

		// supporting web content
		'/component/nav-item': 'Nav Item',
		'/component/three-up-adapter': '3-up Adapter',
		'/component/two-up-adapter': '2-up Adapter',
		'/component/one-up-adapter': '1-up Adapter',
		'/component/latest-content-marker': 'Latest Content Marker',
		'/component/newsletter-signup-marker': 'Newsletter Signup Marker',
		'/component/search-bar-marker': 'Search Bar Marker',
		'/component/search-result-marker': 'Search Result Marker',
		'/component/basic-card': 'Basic Card',
		'/component/text-block': 'Text Block',
		'/component/slider': 'Slider',
		'/component/image-hero': 'Hero Image',
		'/component/threetabslider': '3-tab Slider',
		'/component/fourcardcallout': '4-card Callout',
		'/component/quoteslider': 'Quote Slider',
		'/component/congressheader': 'Congress Header',
		'/component/anchor-menu': 'Anchor Menu',
		'/component/congress-intro': 'Congress Intro',
		'/component/full-width-banner': 'Full Width Banner',
		'/component/fullheighttext': 'Full Height Text',
		'/component/iframeembed': 'iFrame Embed',
		'/component/congressrego': 'Congress Rego',
		'/component/speaker-agenda': 'Speaker Agenda',
	};
}

export function labelPlural() {
	return {
		// taxonomy & inheritance
		'/component/level-descriptor': 'Section Defaults',

		// generic content
		'/component/disclaimer': 'Disclaimers',
		'/component/address': 'Addresses',
		'/component/congress': 'Congress',
		'/component/patient-brochure': 'Patient Brochures',
		'/component/metagenics-update': 'Metagenics Update Issues',
		'/component/seminar': 'Seminars',
		'/component/case-study': 'Case Studies',
		'/component/protocol': 'Protocols',
		'/component/metaproduct': 'Products',
		'/component/tech-data': 'Technical Data Sheets',
		'/component/infographic': 'Infographics',
		'/component/blog-post': 'Blog Posts',
		'/component/metagenics-update-article': 'Articles',
		'/component/webinar': 'Webinars',
		'/component/podcast': 'Podcasts',
		'/component/pdf': 'PDFs',
		'/component/diet': 'Diets',
		'/component/patient-booklet': 'Patient Booklets',
		'/component/patient-handout': 'Patient Handouts',
		'/component/patientnewsletter': 'Patient Newsletters',
		'/component/practitioner-resource': 'Practitioner Resources',
		'/component/recipe': 'Recipes',
		'/component/questionnaire': 'Questionnaires',
		'/component/clinicalguide': 'Clinical Guides',
		'/component/scientific-poster': 'Scientific Posters',
		'/component/shopping-list': 'Shopping Lists',
		'/component/video': 'Videos',
		'/component/patient-video': 'Patient Videos',
		'/component/functional-foundations': 'Functional Foundations',
		'/component/upcomingeducation': 'Education Calendar',

		// pages
		'/page/generic': 'Landing Pages',
		'/page/trade-presenter': 'Practitioner Guides',
		'/page/tester': 'Test Pages',

		// supporting web content
		'/component/nav-item': 'Nav Items',
		'/component/three-up-adapter': '3-up Adapters',
		'/component/two-up-adapter': '2-up Adapters',
		'/component/one-up-adapter': '1-up Adapters',
		'/component/latest-content-marker': 'Latest Content Markers',
		'/component/newsletter-signup-marker': 'Newsletter Signup Markers',
		'/component/search-bar-marker': 'Search Bar Markers',
		'/component/search-result-marker': 'Search Result Markers',
		'/component/basic-card': 'Basic Cards',
		'/component/text-block': 'Text Blocks',
		'/component/slider': 'Sliders',
		'/component/image-hero': 'Hero Images',
		'/component/threetabslider': '3-tab Sliders',
		'/component/fourcardcallout': '4-card Callouts',
		'/component/quoteslider': 'Quote Sliders',
		'/component/congressheader': 'Congress Headers',
		'/component/anchor-menu': 'Anchor Menus',
		'/component/congress-intro': 'Congress Intros',
		'/component/full-width-banner': 'Full Width Banners',
		'/component/fullheighttext': 'Full Height Texts',
		'/component/iframeembed': 'iFrame Embeds',
		'/component/congressrego': 'Congress Regos',
		'/component/speaker-agenda': 'Speaker Agendas',
	};
}

export function getSearchableTypeCodes() {
	return [
		'/page/trade-presenter',
		'/component/tech-data',
		'/component/infographic',
		'/component/seminar',
		'/component/congress',
		'/component/webinar',
		'/component/functional-foundations',
		'/component/podcast',
		'/component/protocol',
		'/component/metaproduct',
		'/component/metagenics-update-article',
		'/component/case-study',
		'/component/patient-booklet',
		'/component/patient-brochure',
		'/component/blog-post',
		'/component/questionnaire',
		'/component/patient-handout',
		'/component/patientnewsletter',
		'/component/practitioner-resource',
		'/component/recipe',
		'/component/patient-video',
		'/component/diet',
		'/component/shopping-list',
		'/component/scientific-poster',
		'/component/clinicalguide',
		'/component/metagenics-update',
		'/component/upcomingeducation',

		// todo: update 2020-06-17 by Tony Field: these generic types could be searchable but are suppressed
		// '/component/basic-card', --> not currently used
		// '/component/pdf', --> broken up into other types
		// '/component/video', --> broken up into other types

		// todo: update 2020-06-17 by Tony Field: these types are suppressed and should not be made searchable
		// '/page/generic',
		// '/page/tester',
		// '/component/3wrapgrid',
		// '/component/address',
		// '/component/announcementcard',
		// '/component/category',
		// '/component/disclaimer',
		// '/component/fourcardcallout',
		// '/component/frontpageheader',
		// '/component/fullwidthtwocol',
		// '/component/image-hero',
		// '/component/ingredient',
		// '/component/latest-content-marker',
		// '/component/level-descriptor',
		// '/component/nav-item',
		// '/component/newsletter-signup-marker',
		// '/component/product',
		// '/component/quoteslider',
		// '/component/search-bar-marker',
		// '/component/search-result-marker',
		// '/component/slider',
		// '/component/tag',
		// '/component/text-block',
		// '/component/textblockwithcta',
		// '/component/three-up-adapter',
		// '/component/threetabslider',
	];
}

export function getSearchableTypeCodesInOrder() {
	return [
		'/component/metagenics-update-article',
		'/component/blog-post',
		'/component/case-study',
		'/component/clinicalguide',
		// '/component/metaproduct',
		'/component/congress',
		'/component/diet',
		'/component/functional-foundations',
		'/component/infographic',
		'/component/metagenics-update',
		'/component/patient-booklet',
		'/component/patient-brochure',
		'/component/patient-handout',
		'/component/patientnewsletter',
		'/component/practitioner-resource',
		'/component/recipe',
		'/component/patient-video',
		'/component/podcast',
		'/page/trade-presenter',
		'/component/protocol',
		'/component/questionnaire',
		'/component/scientific-poster',
		'/component/seminar',
		'/component/shopping-list',
		'/component/tech-data',
		'/component/webinar',
		'/component/upcomingeducation',
	];
}

export function getSearchableTypes() {
	const list = [];
	getSearchableTypeCodes().forEach((type) => {
		list.push({
			title: labelSingular()[type],
			contentType: type,
		});
	});
	return list;
}

export function getSearchableTypesInOrder() {
	const list = [];
	getSearchableTypeCodesInOrder().forEach((type) => {
		list.push({
			title: labelSingular()[type],
			contentType: type,
		});
	});
	return list;
}

export function supportedSlugDetailViewLocations() {
	return ['/tech-data/*', '/protocols/*'];
}

export function getTypeForSlug(slug) {
	switch (slug) {
		case '/tech-data':
			return '/component/tech-data';
		case '/protocols':
			return '/component/protocol';
		default:
			return null;
	}
}

export function supportedDetailViewLocations() {
	return [
		'/congresses/*',
		'/patient-brochures/*',
		'/patient-booklets/*',
		'/patient-handouts/*',
		'/patient-newsletters/*',
		'/practitioner-resource/*',
		'/metagenics-update/*',
		'/seminars/*',
		'/case-studies/*',
		'/tech-data/*',
		'/infographics/*',
		'/blog-post/*',
		'/metagenics-update/*',
		'/webinars/*',
		'/podcasts/*',
		'/pdfs/*',
		'/shopping-lists/*',
		'/videos/*',
		'/recipes/*',
		'/upcoming-education/*',
	];
}
