// language=GraphQL
export default `
    query navQuery {
        side_nav_content: contentItems(limit: 2000) {
            total
            items {
                localId(filter: {regex: "/site/components/nav/.*"})
                ...f_std_fields
                ... on component_nav__item {
                    title_s
                    menuImage_s
                    url_s
                }
            }
        }
    }

    fragment f_std_fields on ContentItem {
        guid: objectId
        path: localId
        contentTypeId: content__type
        dateCreated: createdDate_dt
        dateModified: lastModifiedDate_dt
        label: internal__name
    }
`