import React, { Suspense, useEffect, useState } from 'react';
import Layout from '../../common/Layout';
import { Helmet } from 'react-helmet';
import { useSiteInfo } from '../../crafter_site_core/hooks';
import CircularProgressSpinner from '../../crafter_site_core/CircularProgressSpinner';
import SearchResultSection from './SearchResult/SearchResultSection';
import SearchBar from '../web_components/SearchBar';
import { getDefaultQueryInfo } from '../../common/SearchQueryInfo';
import { useGlobalContext } from '../../crafter_site_core/context';

export default function () {
  const [queryDetails, updateQueryDetails] = useGlobalContext();
  const [resetComplete, setResetComplete] = useState(false);
  // go get the item
  useEffect(() => {
    if (!resetComplete) {
      const q = queryDetails.searchTerm;
      const ct = queryDetails.contentTypes;
      const defqd = getDefaultQueryInfo();
      defqd.searchTerm = q;
      defqd.contentTypes = ct;
      updateQueryDetails(defqd);
      setResetComplete(true);
    }
  }, [resetComplete, queryDetails, updateQueryDetails]);

  const siteinfo = useSiteInfo();
  return (
    <Layout>
      <Helmet>
        <meta charSet='utf-8' />
        <title>{'Search | ' + siteinfo?.siteName_s}</title>
      </Helmet>
      <Suspense
        fallback={
          <CircularProgressSpinner
            message={'Loading content...'}
            technical={'SearchResults'}
          />
        }
      >
        <SearchBar />
        <SearchResultSection />
      </Suspense>
    </Layout>
  );
}
