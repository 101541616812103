import React, { Suspense } from 'react';

import { BrowserRouter, Route, useHistory } from 'react-router-dom';
import DynamicRoute from './DynamicRoute';
import { AnimatedSwitch, spring } from 'react-router-transition';
import CircularProgressSpinner from './CircularProgressSpinner';
import Analytics from 'react-router-ga';

import { ThemeProvider } from '@material-ui/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import theme from '../utils/constants';
import useAnchors from '../utils/useAnchors';
import LeftNav from '../common/LeftNav';
import PageMenu from '../common/PageMenu';
import ContactButton from '../common/ContactButton';
import MobileFooterMenu from '../common/MobileFooterMenu';
import PractitionerAlert from '../common/PractitionerAlert';
import CSSBaseline from '@material-ui/core/CssBaseline';
import FeaturedContentPopup from '../common/FeaturedContentPopup';
import FeaturedContentPopupMobile from '../common/FeaturedContentPopupMobile';

import '../index.scss';
import { useLeftNavigation, useSiteInfo } from './hooks';
import CuratedSearchResult from '../crafter_types/pages/CuratedSearchResult';
import EnterpriseContentDetailView from '../crafter_types/EnterpriseContentDetailView';
import {
  supportedDetailViewLocations,
  supportedSlugDetailViewLocations
} from './contentTypeMap';
import SearchResult from '../crafter_types/pages/SearchResult';
import FrontSearchResult from '../crafter_types/pages/FrontSearchResult';
import { IS_CO_NZ, IS_COM_AU } from './CrafterApp';
import EnterpriseContentDetailViewSlugSupport from '../crafter_types/EnterpriseContentDetailViewSlugSupport';

function mapStyles(styles) {
  return {
    opacity: styles.opacity
  };
}

function bounce(val) {
  return spring(val, {
    stiffness: 330,
    damping: 22
  });
}

const bounceTransition = {
  atEnter: {
    opacity: 0
  },
  atLeave: {
    opacity: bounce(0)
  },
  atActive: {
    opacity: bounce(1)
  }
};

export default function Router() {
  // preload siteInfo so that we don't double-load it.
  const matchesSm = useMediaQuery('(max-width:640.55px)');
  useSiteInfo();
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <CSSBaseline />
        <LeftNav />
        <Suspense
          fallback={
            <CircularProgressSpinner
              message='Loading content...'
              technical='Router'
            />
          }
        >
          <TheActualRoutes />
        </Suspense>
        {matchesSm ? window.location.pathname !== '/landing-pages/welcome-offer' && <FeaturedContentPopupMobile /> : window.location.pathname !== '/landing-pages/welcome-offer' && <FeaturedContentPopup />}
        <ContactButton />
        <PractitionerAlert />
        <PageMenu />
        <MobileFooterMenu />
      </ThemeProvider>
    </BrowserRouter>
  );
}

export function TheActualRoutes() {
  const curatedNavigation = useLeftNavigation();
  const analyticsId = IS_COM_AU
    ? 'UA-171529296-1'
    : IS_CO_NZ
    ? 'UA-171529296-2'
    : 'debug';
  let history = useHistory();
  useAnchors(history, 5000);
  if (curatedNavigation) {
    return (
      <>
        <Analytics id={analyticsId}>
          <AnimatedSwitch
            atEnter={bounceTransition.atEnter}
            atLeave={bounceTransition.atLeave}
            atActive={bounceTransition.atActive}
            mapStyles={mapStyles}
            className='route-wrapper'
          >
            <Route exact path='/frontsearch' component={FrontSearchResult} />
            <Route exact path='/search' component={SearchResult} />
            {supportedSlugDetailViewLocations().map((allowedPath) => (
              <Route
                path={allowedPath}
                component={EnterpriseContentDetailViewSlugSupport}
                key={allowedPath}
                exact
              />
            ))}
            {supportedDetailViewLocations().map((allowedPath) => (
              <Route
                path={allowedPath}
                component={EnterpriseContentDetailView}
                key={allowedPath}
                exact
              />
            ))}
            {curatedNavigation.map((nav, index) => (
              <Route
                path={nav.url}
                component={CuratedSearchResult}
                key={index}
                exact
              />
            ))}
            <Route path='/*' component={DynamicRoute} />

            {/* This covers the 404 case */}
          </AnimatedSwitch>
        </Analytics>
      </>
    );
  } else {
    // These HAVE to be pre-loaded or we get really weird behaviour.
    return (
      <CircularProgressSpinner
        message='Loading content...'
        technical={'Router loading left navigation'}
      />
    );
  }
}
