import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@material-ui/core/Box';

import Icon from '../utils/icon';

const useStyles = makeStyles((theme) => ({
  button: {
    position: 'absolute',
    bottom: 0,
    left: 200,
    zIndex: 2,
  },
  dialog: {
    zIndex: '1303 !important',
    '& .MuiPaper-rounded': {
      borderRadius: theme.spacing(4),
    },
  },
  dialogcontent: {
    color: theme.palette.primary.main,
  },
  dialogactions: {
    marginRight: theme.spacing(2),
  },
}));

export default function PractitionerAlert() {
  const isPractitioner = localStorage.getItem('isPractitioner');
  const [modalOpen, setModalOpen] = useState(false);

  function OpenPractitionerModal() {
    if (isPractitioner == null && modalOpen === false) {
      setModalOpen(true);
    }
  }

  const handleClose = () => {
    localStorage.setItem('isPractitioner', true);
    setModalOpen(false);
  };

  const classes = useStyles();

  useEffect(() => {
    OpenPractitionerModal();
  });

  return (
    <>
      <Dialog
        open={modalOpen}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        className={classes.dialog}
      >
        <Box width={1} display='flex' justifyContent='center' pt={4} pb={2}>
          <Icon name='practitioner-alert' />
        </Box>
        <DialogTitle id='alert-dialog-title'>
          {'Are you a Healthcare Practitioner?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id='alert-dialog-description'
            className={classes.dialogcontent}
          >
            This site is intended as an information source for Healthcare
            Practitioners only. By clicking ‘I am a practitioner’ you agree to
            the Terms of Use of this site.
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogactions}>
          <Button
            onClick={handleClose}
            color='secondary'
            autoFocus
            disableFocusRipple
            variant='outlined'
          >
            I am a practitioner
          </Button>
          <Button color='secondary'>
            <a
              href='https://www.metagenics.com.au/FindAPractitioner'
              target='_blank'
              rel='noreferrer noopener'
            >
              I am a patient
            </a>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
