import { createMuiTheme } from '@material-ui/core/styles';

const drawerWidthConst = 135;

const theme = createMuiTheme({
  palette: {
    primary: {
      lightest: '#F5F7FA',
      lighter: '#c8d3e1',
      light: '#5f7fa0',
      main: '#506a88',
      dark: '#233f5b',
      darkest: '#002639',
      contrastText: '#002a54',
      highlight: '#6BCFD9',
    },
    secondary: {
      main: '#6b98d9',
      light: '#9ec9ff',
      dark: '#366aa7',
      contrastText: '#ffffff',
    },
    text: {
      primary: '#002a54',
      secondary: '#ffffff',
    },
    tablebackground: {
      main: '#bdd6ee',
    },
    warning: {
      main: '#f32013',
    },
    alerts: {
      danger: '#f32013',
    },
  },
  typography: {
    h1: {
      fontSize: '3rem',
      '@media (max-width:960px)': {
        fontSize: '2.5rem',
      },
    },
    h2: {
      fontSize: '2.25rem',
      '@media (max-width:960px)': {
        fontSize: '2rem',
      },
    },
    h3: {
      fontSize: '1.875rem',
      '@media (max-width:960px)': {
        fontSize: '1.75rem',
      },
    },
    h4: {
      fontSize: '1.5rem',
      '@media (max-width:960px)': {
        fontSize: '1.25rem',
      },
    },
    h5: {
      fontSize: '1.125rem',
      fontWeight: 600,
      '@media (max-width:960px)': {
        fontSize: '1rem',
      },
    },
    h6: {
      fontSize: '0.925rem',
      fontWeight: 700,
      lineHeight: '1.5',
    },
    body1: {
      fontSize: '1.05rem',
      fontWeight: 400,
      lineHeight: '1.5',
    },
    body2: {
      fontSize: '0.875rem',
      lineHeight: '1.5',
    },
  },
});

export { drawerWidthConst };
export default theme;
