import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import {
  useCategoryOptions,
  useIngredientOptions
} from '../../../crafter_site_core/hooks';
import {
  getSearchableTypes,
  getSearchableTypesInOrder
  // labelPlural,
} from '../../../crafter_site_core/contentTypeMap';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import ClearAllRoundedIcon from '@material-ui/icons/ClearAllRounded';
import Box from '@material-ui/core/Box';
import { useGlobalContext } from '../../../crafter_site_core/context';
import { drawerWidthConst } from '../../../utils/constants';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

const drawerWidth = drawerWidthConst;

const useStyles = makeStyles((theme) => ({
  searchresultscontainer: {
    marginTop: 24,
    [theme.breakpoints.down('sm')]: {
      marginTop: 18
    }
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default
  },
  searchbarlg: {
    marginBottom: theme.spacing(2)
  },
  searchbarmd: {
    [theme.breakpoints.up('lg')]: {
      display: 'none'
    }
    // [theme.breakpoints.down('sm')]: {
    //   display: 'none',
    // },
  },
  form: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(4),
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(0)
    }
  },
  input: {
    flexGrow: 1,
    padding: theme.spacing(0, 2),
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.spacing(2),
    border: '1px solid #6b98d9',

    // [theme.breakpoints.between('sm', 'md')]: {
    //   width: '100%',
    // },
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  },
  searchfilter: {
    '& label': {
      color: theme.palette.text.primary
    }
    // [theme.breakpoints.down('sm')]: {
    //   width: 215,
    // },
  },
  searchfilterfc: {
    border: '1px solid #6b98d9',
    borderRadius: theme.spacing(1)
  },
  filterscontainer: {
    width: '91.3%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      display: 'flex',
      flexWrap: 'wrap'
    }
  },
  filtercontainer: {
    width: '23%',
    [theme.breakpoints.down(662)]: {
      width: '32.3%'
    },
    [theme.breakpoints.down(380)]: {
      width: '95%',
      paddingLeft: 0,
      paddingTop: 4
    }
  },
  searchbutton: {
    backgroundColor: theme.palette.common.white,
    border: theme.palette.secondary.main + ' 2px solid',
    position: 'absolute',
    zIndex: 1,
    height: 53,
    width: 53,
    right: '-12px',
    [theme.breakpoints.down('sm')]: {
      right: '-10px'
    }
  },
  selectallbuttonparent: {
    paddingLeft: theme.spacing(1),
    [theme.breakpoints.down(662)]: {
      width: '100%',
      paddingRight: theme.spacing(1),
      paddingTop: 8,
      paddingLeft: 0
    }
  },
  selectallbuttoncontainer: {
    [theme.breakpoints.down(662)]: {
      width: '100%'
    }
  }
}));

export default function SearchBarMd(props) {
  const [queryDetails, updateQueryDetails] = useGlobalContext();
  const [q, setQ] = useState(queryDetails.searchTerm);
  const [typeFilterOpen, setTypeFilterOpen] = useState(false);
  const classes = useStyles();
  const theme = useTheme();

  const [selectedAll, setSelectedAll] = useState(false);
  const categories = useCategoryOptions();
  const ingredients = useIngredientOptions();
  const types = getSearchableTypes();
  const sortedTypes = getSearchableTypesInOrder();

  const [searchPrompt, setSearchPrompt] = useState('Start a new search');

  useEffect(() => {
    if (
      queryDetails.ingredientKeys != null ||
      queryDetails.categoryKeys != null ||
      queryDetails.contentTypes != null
    ) {
      setSearchPrompt('Filter results by keyword');
    }
  }, [
    queryDetails.ingredientKeys,
    queryDetails.categoryKeys,
    queryDetails.contentTypes
  ]);

  function submitSearchForm(event) {
    event.preventDefault();
    queryDetails.searchTerm = q;
    updateQueryDetails(queryDetails);
  }

  function selectAll() {
    queryDetails.contentTypes = sortedTypes.map((item) => item.contentType);
    updateQueryDetails(queryDetails);
    setSelectedAll(true);
  }

  function deselectAll() {
    queryDetails.contentTypes = [];
    updateQueryDetails(queryDetails);
    setSelectedAll(false);
  }

  if (categories && ingredients && types) {
    return (
      <Box
        left={drawerWidth}
        display='flex'
        flexDirection='column'
        marginTop={8}
      >
        {/*<form onSubmit={submitSearchForm}>*/}
        <Box display='flex' width={1} flexGrow={1} pt={1} alignItems='start'>
          <FormControl style={{ width: '94%' }}>
            <Paper component='form' className={classes.form} elevation={0}>
              <TextField
                id='textSearchFieldMD'
                label={searchPrompt}
                value={q || ''}
                onChange={(e) => {
                  setQ(e.target.value);
                }}
                onSubmit={submitSearchForm}
                onKeyPress={(ev) => {
                  if (ev.key === 'Enter') {
                    ev.preventDefault();
                    submitSearchForm(ev);
                  }
                }}
                className={classes.input + ' promotionalcard'}
              />
              <IconButton
                component='span'
                type='submit'
                aria-label='search'
                onClick={submitSearchForm}
                onSubmit={submitSearchForm}
                className={classes.searchbutton}
              >
                <SearchIcon style={{ color: theme.palette.secondary.main }} />
              </IconButton>
            </Paper>
          </FormControl>
        </Box>
        <Box
          display='flex'
          flexGrow={1}
          py={1}
          pl={2}
          className={classes.filterscontainer}
        >
          <Box className={classes.filtercontainer}>
            <FormControl
              style={{
                border: '1px solid #6b98d9',
                borderRadius: theme.spacing(2),
                marginRight: theme.spacing(2),
                width: '100%'
              }}
            >
              <InputLabel id='ingredientMenuLabel'>Ingredient</InputLabel>
              <Select
                labelId='ingredientMenuLabel'
                id='ingredients'
                multiple
                onChange={(e) => {
                  queryDetails.ingredientKeys = e.target.value;
                  updateQueryDetails(queryDetails);
                }}
                renderValue={(selected) => (selected ? 'View Selected' : '')}
                value={
                  queryDetails.ingredientKeys ? queryDetails.ingredientKeys : []
                }
                className={classes.searchfilter}
              >
                {ingredients.map((item) => (
                  <MenuItem key={item.key} value={item.key}>
                    <Checkbox
                      checked={
                        queryDetails.ingredientKeys?.indexOf(item.key) > -1
                      }
                    />
                    <ListItemText primary={item.title} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box pl={1} className={classes.filtercontainer}>
            <FormControl
              style={{
                border: '1px solid #6b98d9',
                borderRadius: theme.spacing(2),
                marginRight: theme.spacing(2),
                width: '100%'
              }}
            >
              <InputLabel id='categoryMenuLabel'>Category</InputLabel>
              <Select
                labelId='categoryMenuLabel'
                id='categories'
                multiple
                value={
                  queryDetails.categoryKeys ? queryDetails.categoryKeys : []
                }
                onChange={(e) => {
                  queryDetails.categoryKeys = e.target.value;
                  updateQueryDetails(queryDetails);
                }}
                renderValue={(selected) => (selected ? 'View Selected' : '')}
                className={classes.searchfilter}
              >
                {categories.map((item) => (
                  <MenuItem key={item.key} value={item.key}>
                    <Checkbox
                      checked={
                        queryDetails.categoryKeys?.indexOf(item.key) > -1
                      }
                    />
                    <ListItemText primary={item.title} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box pl={1} className={classes.filtercontainer}>
            <FormControl
              style={{
                border: '1px solid #6b98d9',
                borderRadius: theme.spacing(2),
                width: '100%'
              }}
            >
              <InputLabel id='typeMenuLabel'>Type</InputLabel>
              <Select
                MenuProps={{
                  getContentAnchorEl: () => null
                }}
                onOpen={() => setTypeFilterOpen(true)}
                onClose={() => setTypeFilterOpen(false)}
                labelId='typeMenuLabel'
                id='types'
                multiple
                value={
                  queryDetails.contentTypes ? queryDetails.contentTypes : []
                }
                onChange={(e) => {
                  queryDetails.contentTypes = e.target.value;
                  updateQueryDetails(queryDetails);
                }}
                renderValue={(selected) => (selected ? 'View Selected' : '')}
                className={classes.searchfilter}
              >
                {types.map((item) => (
                  <MenuItem key={item.contentType} value={item.contentType}>
                    <Checkbox
                      checked={
                        queryDetails.contentTypes?.indexOf(item.contentType) >
                        -1
                      }
                    />
                    <ListItemText primary={item.title} />
                  </MenuItem>
                ))}
              </Select>
              {typeFilterOpen ? (
                <Box position='absolute' top={-135} right={-122} zIndex={1300}>
                  <Box
                    width={35}
                    height={35}
                    bgcolor={theme.palette.error.light}
                    borderRadius={18}
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                  >
                    <IconButton aria-label='close-menu'>
                      <CloseRoundedIcon style={{ color: '#fff' }} />
                    </IconButton>
                  </Box>
                </Box>
              ) : null}
            </FormControl>
          </Box>
          <Box className={classes.selectallbuttonparent}>
            <FormControl className={classes.selectallbuttoncontainer}>
              <Button
                onClick={selectedAll ? deselectAll : selectAll}
                className='button-main'
                variant='contained'
                startIcon={
                  selectedAll ? <ClearAllRoundedIcon /> : <DoneAllIcon />
                }
                style={{
                  height: '53px',
                  backgroundColor: selectedAll
                    ? theme.palette.error.light
                    : theme.palette.secondary.main
                }}
              >
                {selectedAll ? 'Deselect All Types' : 'Select All Types'}
              </Button>
            </FormControl>
          </Box>
        </Box>
        {/*</form>*/}
      </Box>
    );
  } else {
    return null;
  }
}
