import React, { Suspense, useEffect, useState } from 'react';
import Layout from '../common/Layout';
import SummaryInfo from './SummaryInfo';

import { Helmet } from 'react-helmet';
import { neverResource, useSiteInfo } from '../crafter_site_core/hooks';
import { parseDescriptor, preParseSearchResults } from '@craftercms/content';
import CircularProgressSpinner from '../crafter_site_core/CircularProgressSpinner';
import ContentType from '../crafter_site_core/ContentType';
import { createResource } from '../crafter_site_core/utils';
import { map } from 'rxjs/operators';
import { createQuery, search } from '@craftercms/search';

export default function (props) {
  const { itemPath = itemPathFromUrl(props.location.pathname) } = props;
  const [itemJson, setItemJson] = useState(neverResource);

  // go get the item
  useEffect(() => {
    const query = createQuery('elasticsearch');
    query.query = {
      query: {
        bool: {
          must: [
            {
              term: {
                localId: itemPath,
              },
            },
          ],
        },
      },
      from: 0,
      size: 1,
    };
    setItemJson(
      createResource(() =>
        search(query)
          .pipe(
            map((item) => {
              return parseDescriptor(
                preParseSearchResults(item.hits[0]._source)
              );
            })
          )
          .toPromise()
      )
    );
  }, [itemPath]);

  return (
    <Suspense
      fallback={
        <CircularProgressSpinner
          message={'Loading content...'}
          technical={'Enterprise content detail view'}
        />
      }
    >
      <ECDetailRenderer resource={itemJson} />
    </Suspense>
  );
}

/**
 * This component has to exist as a separate component from the suspense block, in order for the read() to work.
 * @param resource
 * @returns {*}
 * @constructor
 */
export function ECDetailRenderer({ resource }) {
  const siteinfo = useSiteInfo();
  const contentItem = resource.read();
  const summary = SummaryInfo(contentItem);
  return (
    <>
      <Layout>
        <Helmet>
          <meta charSet='utf-8' />
          <title>{summary.title + ' | ' + siteinfo?.siteName_s}</title>
          <meta name='description' content='One Stop Learning Hub' />
        </Helmet>
        <ContentType model={contentItem} format='DETAIL' />
        {/*<pre>{JSON.stringify(contentItem, null, 2)}</pre>*/}
      </Layout>
    </>
  );
}

export function urlFromItemPath(itemPath) {
  if (
    itemPath?.startsWith('/site/components/content/') &&
    itemPath?.endsWith('.xml')
  ) {
    const start = '/site/components/content'.length;
    const end = itemPath.lastIndexOf('.xml');
    const url = itemPath.substring(start, end);
    // console.log("The URL is:" + url);
    return url;
  } else {
    return '/404'; // not supported - no hacking!
  }
}

function itemPathFromUrl(url) {
  const path = '/site/components/content' + url + '.xml';
  // console.log("The path is:" + path);
  return path;
}