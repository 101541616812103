import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';

import { useLeftNavigation } from '../crafter_site_core/hooks';
import LeftNavExtension from './LeftNav/LeftNavExtension';

import Icon from '../utils/icon';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    height: 70,
    textAlign: 'center',
    zIndex: 100,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

export default function MobileFooterMenu() {
  const nav = useLeftNavigation();
  const nav2 = useLeftNavigation();
  const classes = useStyles();
  const [leftSide, setLeftSide] = useState(false);
  const [listIndex, setListIndex] = useState(0);
  const setLeftSideState = () => {
    if (leftSide === false) {
      setLeftSide(true);
    }
  };
  const setLeftSideOnClickState = () => {
    if (leftSide === true) {
      setLeftSide(false);
    }
  };
  const setListIndexState = (newListIndex) => {
    setListIndex(newListIndex);
  };
  const sideListHealthCategories = [];
  const [activeMenuItem, setActiveMenuItem] = useState(null);
  const setActiveMenuItemState = (mi) => {
    setActiveMenuItem(mi);
  };
  const [sideListMenuItems, setSideListMenuItems] = useState(
    sideListHealthCategories
  );
  const setSideListMenuItemsState = (sideList) => {
    setSideListMenuItems(sideList);
  };

  return (
    <>
      <BottomNavigation
        className={'no-print ' + classes.root}
        showLabels={true}
      >
        {nav
          ?.filter(function (ni) {
            return ni.__depth === 1;
          })
          .map((level1MenuItem, index) => (
            <BottomNavigationAction
              key={index}
              className={classes.listitem}
              label={level1MenuItem.linktext}
              icon={
                <Icon
                  name={level1MenuItem.img}
                  widthsize={32}
                  heightsize={32}
                />
              }
              onClick={() => {
                setLeftSideState();
                setListIndexState(index);
                setActiveMenuItemState(level1MenuItem);
                setSideListMenuItemsState(
                  nav2.filter(function (ni2) {
                    return ni2.__sortString.startsWith(
                      level1MenuItem.__sortString + '/'
                    );
                  })
                );
              }}
            ></BottomNavigationAction>
          ))}
      </BottomNavigation>
      <LeftNavExtension
        leftSide={leftSide}
        clicked={setLeftSideOnClickState}
        listindex={listIndex}
        activeitem={activeMenuItem}
        sidelist={sideListMenuItems}
      />
    </>
  );
}
