// language=GraphQL
export default `
    query categoriesQuery {
        component_category(limit: 500, sortBy: "taxItemName_s", sortOrder: ASC) {
            total
            items {
                key: localId
                name: taxItemName_s
            }
        }
    }

`