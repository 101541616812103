import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { motion } from 'framer-motion';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import { useSiteInfo } from '../crafter_site_core/hooks';
import { useHistory } from 'react-router-dom';

export default function FeaturedContentPopup() {
  let closedPopup = sessionStorage.getItem('closedPopup');
  let hiddenPopup = sessionStorage.getItem('hiddenPopup');
  const siteinfo = useSiteInfo();
  const [hiddenState, sethiddenState] = useState(true);
  const [closedState, setClosedState] = useState(false);
  const [backgroundImage, setBackgroundImage] = useState('');
  const [buttonText, setButtonText] = useState('');
  const [link, setLink] = useState('');
  const [mainColour, setMainColour] = useState('');
  const [shown, setShown] = useState('');

  const history = useHistory();

  // let backgroundImage = '';
  // let buttonText = '';
  // let link = '';
  // let mainColour = '';
  // let shown = '';

  // if (siteinfo !== null) {
  //   shown = siteinfo.shown_b;
  //   backgroundImage = siteinfo.backgroundImage_s;
  //   buttonText = siteinfo.buttonText_s;
  //   link = siteinfo.link_s;
  //   mainColour = siteinfo.mainColour_s;
  // }

  useEffect(() => {
    if (siteinfo !== null) {
      if (window.location.pathname !== siteinfo.pageAO_s) {
        setShown(siteinfo.shown_b);
        setBackgroundImage(siteinfo.backgroundImage_s);
        setButtonText(siteinfo.buttonText_s);
        setLink(siteinfo.link_s);
        setMainColour(siteinfo.mainColour_s);
      } else {
        setShown(siteinfo.shownAO_b);
        setBackgroundImage(siteinfo.backgroundImageAO_s);
        setButtonText(siteinfo.buttonTextAO_s);
        setLink(siteinfo.linkAO_s);
        setMainColour(siteinfo.mainColourAO_s);
      }
    }
  }, [siteinfo]);

  const variants = {
    hidden: {
      right: -660,
    },
    shown: {
      right: 0,
    },
  };

  const iconVariants = {
    open: {
      rotate: 180,
      left: 0,
      marginLeft: 9,
    },
    close: {
      rotate: 0,
      left: 12,
      marginLeft: 0,
    },
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!hiddenPopup) {
        sethiddenState(false);
      }
    }, 10000);
    return () => clearTimeout(timer);
  }, [hiddenPopup]);

  const handleClose = () => {
    sessionStorage.setItem('closedPopup', true);
    setClosedState(true);
  };

  const handleOpen = () => {
    if (hiddenState) {
      sessionStorage.setItem('hiddenPopup', false);
      sethiddenState(false);
    } else if (!hiddenState) {
      sessionStorage.setItem('hiddenPopup', true);
      sethiddenState(true);
    }
  };

  useEffect(() => {
    return history.listen((location) => {
      if (siteinfo !== null && location.pathname === siteinfo.pageAO_s) {
        setShown(siteinfo.shownAO_b);
        setBackgroundImage(siteinfo.backgroundImageAO_s);
        setButtonText(siteinfo.buttonTextAO_s);
        setLink(siteinfo.linkAO_s);
        setMainColour(siteinfo.mainColourAO_s);
      } else if (siteinfo !== null && location.pathname !== siteinfo.pageAO_s) {
        setShown(siteinfo.shown_b);
        setBackgroundImage(siteinfo.backgroundImage_s);
        setButtonText(siteinfo.buttonText_s);
        setLink(siteinfo.link_s);
        setMainColour(siteinfo.mainColour_s);
      }
    });
  });

  return (
    <>
      {!closedPopup && (
        <Box display={shown ? 'inherit' : 'none'}>
          <Box
            position='fixed'
            right={0}
            bottom='46%'
            style={{ zIndex: 98 }}
            display={closedState ? 'none' : 'inherit'}>
            <motion.div
              style={{ position: 'absolute' }}
              initial={false}
              variants={variants}
              animate={hiddenState ? 'hidden' : 'shown'}>
              <Box
                position='absolute'
                right={12}
                top={12}
                color='#ffffff'
                style={{ cursor: 'pointer' }}>
                <CancelRoundedIcon onClick={() => handleClose()} />
              </Box>
              <Box
                width={700}
                height={224}
                bgcolor={mainColour}
                borderRadius='24px 0 0 24px'
                display='flex'
                justifyContent='flex-end'
                onClick={() => handleOpen()}>
                <img
                  src={backgroundImage}
                  width={660}
                  height='auto'
                  alt='featured content'
                />
              </Box>
              <Box
                position='absolute'
                left={hiddenState ? 8 : 0}
                top={0}
                bottom={0}
                onClick={() => handleOpen()}
                style={{ cursor: 'pointer' }}
                display='flex'
                alignItems='center'>
                <motion.div
                  variants={iconVariants}
                  animate={hiddenState ? 'open' : 'close'}>
                  <ChevronRightRoundedIcon
                    style={{ fontSize: 32, color: '#fff' }}
                  />
                  <ChevronRightRoundedIcon
                    style={{
                      fontSize: 32,
                      color: '#fff',
                      position: 'absolute',
                      left: 10,
                    }}
                  />
                </motion.div>
              </Box>
              <Box position='absolute' left={51} bottom={21}>
                {link.includes('https://') ? (
                  <a href={link} target='_blank' rel='noopener noreferrer'>
                    <Button
                      variant='contained'
                      color='secondary'
                      endIcon={<ChevronRightRoundedIcon />}
                      className='button-main'
                      onClick={() => handleClose()}>
                      {buttonText}
                    </Button>
                  </a>
                ) : (
                  <NavLink to={link} exact>
                    <Button
                      variant='contained'
                      color='secondary'
                      endIcon={<ChevronRightRoundedIcon />}
                      className='button-main'
                      onClick={() => handleClose()}>
                      {buttonText}
                    </Button>
                  </NavLink>
                )}
              </Box>
            </motion.div>
          </Box>
        </Box>
      )}
    </>
  );
}
