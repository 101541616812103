import React from 'react';
import { NavLink } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import Box from '@material-ui/core/Box';
import { drawerWidthConst } from '../../utils/constants';
import Icon from '../../utils/icon';

import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

const drawerWidth = drawerWidthConst;

const useStyles = makeStyles((theme) => ({
  //Left Menu
  list: {
    width: 250,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '100%',
      position: 'relative'
    }
  },
  drawerbase: {
    marginLeft: drawerWidth,
    [theme.breakpoints.down('md')]: {
      marginLeft: 0 + ' !important'
    }
  },
  closemenu: {
    [theme.breakpoints.up('md')]: {
      display: 'none'
    },
    position: 'sticky',
    bottom: 0,
    height: 50,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.error.light,
    '& ul': {
      padding: 0,
      height: '100%',
      width: '100%'
    }
  },
  closebutton: {
    height: '100%',
    color: theme.palette.common.white
  },
  closebuttonicon: {
    color: theme.palette.common.white
  },
  desktopCloseButton: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  }
}));

export default function LeftNavExtension(props) {
  const classes = useStyles();
  const currentSideListItems = props.sidelist;
  const currentLevel1MenuItem = props.activeitem;
  const sideListMenuMap = currentSideListItems.map(
    (level2MenuItem, index) =>
      level2MenuItem.url && (
        <NavLink to={level2MenuItem.url} exact key={index}>
          <ListItem button onClick={props.clicked}>
            <ListItemText primary={level2MenuItem.linktext} />
            <ListItemSecondaryAction>
              <IconButton edge='end' aria-label='goto' color='secondary'>
                <KeyboardArrowRightIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        </NavLink>
      )
  );

  const SideListHeader = () => (
    <Box
      width={1}
      display='flex'
      flexDirection='column'
      justifyContent='center'
      pl={2}
      py={1}
    >
      <Icon name={currentLevel1MenuItem.img} widthsize={48} heightsize={48} />
      <Typography variant='h5'> {currentLevel1MenuItem.linktext} </Typography>
    </Box>
  );
  const SideListMenu = () => (
    <List style={{ height: '100%' }}>{sideListMenuMap}</List>
  );
  const sideList = () => (
    <div className={classes.list} role='presentation'>
      <SideListHeader />
      <SideListMenu />
      <div className={classes.closemenu}>
        <List>
          <ListItem
            button
            className={classes.closebutton}
            onClick={props.clicked}
          >
            <ListItemIcon>
              <HighlightOffIcon className={classes.closebuttonicon} />
            </ListItemIcon>
            <ListItemText primary='Close Menu' />
          </ListItem>
        </List>
      </div>
    </div>
  );
  return (
    <Drawer
      anchor='left'
      open={props.leftSide}
      className={classes.drawerbase}
      onClose={props.clicked}
    >
      {sideList('left')}
      <Box
        position='absolute'
        height={32}
        width={32}
        right={24}
        top={8}
        zIndex={1302}
      >
        <IconButton
          className={classes.desktopCloseButton}
          onClick={props.clicked}
        >
          <ArrowBackOutlinedIcon color='secondary' />
        </IconButton>
      </Box>
    </Drawer>
  );
}
