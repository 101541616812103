function getTags(tags_o) {
    return tags_o?.map((item, key) => {
        return item.tag;
    });
}

function getIngredients(ingredients_o) {
    return ingredients_o?.map((item, key) => {
            return item.taxItemName_s;
        }
    );
}

function getProducts(products_o) {
    return products_o?.map((item, key) => {
            return {
                name: item.taxItemName_s,
                catchphrase: item.catchphrase_s
            }
        }
    )
}

function getCategories(categories_o) {
    return categories_o?.map((item, key) => {
          return {
              name: item.taxItemName_s,
          }
      }
    )
}

export default function (contentItem) {
    return {
        title: contentItem.title_s,
        subtitle: contentItem.subtitle_s,
        snippet: contentItem.snippet_t,
        cardImage: contentItem.cardImage_s,
        isFeatured: contentItem.isFeatured_b,
        tags: getTags(contentItem.tags_o),
        ingredients: getIngredients(contentItem.ingredients_o),
        products: getProducts(contentItem.products_o),
        categories: getCategories(contentItem.categories_o)
    }
}