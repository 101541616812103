import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Box from '@material-ui/core/Box';
import Icon from '../utils/icon';
import { drawerWidthConst } from '../utils/constants';
import { useLeftNavigation } from '../crafter_site_core/hooks';
import LeftNavExtension from './LeftNav/LeftNavExtension';

import HealthCategoryImage from '../images/health-categories.png';
import KeyIngredientsImage from '../images/key-ingredients.png';
import EducationImage from '../images/education.png';
import ClinicalToolsImage from '../images/clinical-tools.png';
import PatientResourcesImage from '../images/patient-resources.png';
import LogoImage from '../images/mi-logo.png';

export const imgArray = [
  HealthCategoryImage,
  KeyIngredientsImage,
  EducationImage,
  ClinicalToolsImage,
  PatientResourcesImage
];

const drawerWidth = drawerWidthConst;
const isIE = false || !!document.documentMode;

const useStyles = makeStyles((theme) => ({
  //Left Menu
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  drawerPaper: {
    width: drawerWidth,
    zIndex: 1301
  },
  listitem: {
    flexDirection: 'column'
  },
  listitemicon: {
    minWidth: 0
  },
  listitemtext: {
    textAlign: 'center'
  },
  toolbar: theme.mixins.toolbar,
  logocontainer: {
    padding: theme.spacing(2, 0.5),
    '& svg': {
      width: '100%'
    }
  }
}));

export default function LeftNav() {
  const nav = useLeftNavigation();
  const nav2 = useLeftNavigation();
  const classes = useStyles();
  const [leftSide, setLeftSide] = useState(false);
  const [listIndex, setListIndex] = useState(0);
  const setLeftSideState = () => {
    if (leftSide === false) {
      setLeftSide(true);
    }
  };
  const setLeftSideOnClickState = () => {
    if (leftSide === true) {
      setLeftSide(false);
    }
  };
  const setListIndexState = (newListIndex) => {
    setListIndex(newListIndex);
  };
  const sideListHealthCategories = [];
  const [activeMenuItem, setActiveMenuItem] = useState(null);
  const setActiveMenuItemState = (mi) => {
    setActiveMenuItem(mi);
  };
  const [sideListMenuItems, setSideListMenuItems] = useState(
    sideListHealthCategories
  );
  const setSideListMenuItemsState = (sideList) => {
    //console.log("Setting leve2 item: "+JSON.stringify(sideList, null, 2))
    setSideListMenuItems(sideList);
  };
  return (
    <>
      <Drawer
        className={classes.drawer}
        variant='permanent'
        classes={{
          paper: classes.drawerPaper
        }}
        anchor='left'
      >
        <div className={classes.logocontainer}>
          <NavLink to='/' exact>
            {isIE ? (
              <img
                src={LogoImage}
                alt='metagenics institute logo'
                height='auto'
                width='135px'
              />
            ) : (
              <Box px={0.5}>
                <Icon
                  name='metagenics-institute-logo-stacked'
                  heightsize={50}
                  color='#002A54'
                />
              </Box>
            )}
          </NavLink>
        </div>
        <List>
          {nav
            ?.filter(function (ni) {
              return ni.__depth === 1;
            })
            .map((level1MenuItem, index) => (
              <ListItem
                button
                key={index}
                className={classes.listitem + ' listitemie'}
                onClick={() => {
                  setLeftSideState();
                }}
                onMouseEnter={() => {
                  setListIndexState(index);
                  setActiveMenuItemState(level1MenuItem);
                  setSideListMenuItemsState(
                    nav2.filter(function (ni2) {
                      return ni2.__sortString.startsWith(
                        level1MenuItem.__sortString + '/'
                      ); // (add +"/" to the match string to remove the parent
                    })
                  );
                }}
              >
                <ListItemIcon className={classes.listitemicon}>
                  {isIE ? (
                    <img
                      src={imgArray[index]}
                      alt={level1MenuItem.img}
                      width='65px'
                      height='auto'
                    />
                  ) : (
                    <Icon
                      name={level1MenuItem.img}
                      widthsize={35}
                      heightsize={35}
                    />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary={level1MenuItem.linktext}
                  className={classes.listitemtext}
                />
              </ListItem>
            ))}
        </List>
      </Drawer>
      <LeftNavExtension
        leftSide={leftSide}
        clicked={setLeftSideOnClickState}
        listindex={listIndex}
        activeitem={activeMenuItem}
        sidelist={sideListMenuItems}
      />
    </>
  );
}
