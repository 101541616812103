import {useEffect, useReducer, useRef, useState} from 'react';
import {fetchQuery} from './relayEnvironment';
import {useGlobalContext} from './context';
import rightNavQuery from './nav-right.graphql';
import leftNavQuery from './nav-left.graphql';
import LeftNavInfo from '../common/LeftNav/LeftNavInfo';
import siteQuery from '../crafter_site_core/siteinfo.graphql';
import {createQuery, search} from '@craftercms/search';
import {createResource} from './utils';
import {map} from 'rxjs/operators';
import categoriesQuery from './categories.graphql';
import ingredientsQuery from './ingredients.graphql';
import {parseElasticResult} from '../common/SearchResultInfo';

export const neverResource = createResource(() => new Promise(() => void 0));

const antisearchterms = [
	'cancer',
	'Cancer',
	'CANCER',
	'cancers',
	'hiv',
	'HIV',
	'melanoma',
	'Melanoma',
	'MELANOMA',
	'diabetes',
	'Diabetes',
	'DIABETES',
	'atherosclerosis',
	'Atherosclerosis',
	'ATHEROSCLEROSIS',
	'Coronary',
	'coronary',
	'coronary heart disease',
	'Coronary Heart Disease',
	'Ischaemic Heart Disease',
	'ischaemic heart disease',
	'ischaemic',
	'Ischaemic',
	'AIDS',
	'aids',
	'Aids',
	'human immunodeficiency virus',
	'acquired immunodeficiency syndrome',
	'Hep C',
	'hep c',
	'hep C',
	'Hepatitis C',
	'Hepatitis',
	'Hepatitis C virus',
	'hep c virus',
	'malaria',
	'Malaria',
	'Stroke',
	'stroke',
	"alzheimer's disease",
	'alzheimers disease',
	"alzheimer's",
	'alzheimers',
	"Alzheimer's Disease",
	'Alzheimers Disease',
	"Alzheimer's",
	'Alzheimers',
	'Chronic Obstructive Pulmonary Disease',
	'Chronic Obstructive pulmonary disease',
	'COPD',
	'copd',
	'heart attack',
	'Heart Attack',
	'MS',
	'ms',
	'parkinsons',
	'Parkinsons',
	'cirrhosis',
	'Cirrhosis',
	'cirrhosis of the liver',
	'liver cirrhosis',
	'Liver cirrhosis',
	'Liver Cirrhosis',
	'Cirrhosis of the Liver',
	'skin cancer',
	'Skin Cancer',
	'heart disease',
	'Heart Disease',
	'epilepsy',
	'Epilepsy',
	'ulcerative colitis',
	'Ulcerative colitis',
	'Ulcerative Colitis',
	'multiple sclerosis',
	'Multiple Sclerosis',
	'Rheumatism',
	'rheumatism',
	'lupus',
	'Lupus',
	"Parkinson's Disease",
	"parkinson's disease",
	"Parkinson's",
	"parkinson's",
	'Parkinsons Disease',
	'parkinsons disease',
	'Parkinsons',
	'parkinsons',
	'schizophrenia',
	'Schizophrenia',
	'ra',
	'RA',
	'rheumatoid arthritis',
	'Rheumatoid Arthritis',
	'rheumatoid',
	'Rheumatoid',
];

function reducer(state, nextState) {
	return {...state, ...nextState};
}
function compareLeftNavItems(a, b) {
	return -(a.__sortString < b.__sortString);
}
export function useSpreadState(initializerArg, initializer) {
	return useReducer(reducer, initializerArg, initializer);
}

export function useSiteInfo() {
	const [
		{
			siteInfo,
			siteInfoLoading,
			// debug
		},
		update,
	] = useGlobalContext();
	const destroyedRef = useRef(false);
	useEffect(() => {
		return () => {
			destroyedRef.current = true;
		};
	}, []);
	useEffect(() => {
		if (!siteInfo && !siteInfoLoading) {
			update({siteInfoLoading: true});
			fetchQuery({text: siteQuery}).then(({data}) => {
				// const d = data.siteinfo.items[0].debugMode_s === 'Yes';
				!destroyedRef.current &&
					update({
						siteInfo: data.siteinfo.items[0],
						// debug: d,
					});
			});
		}
	}, [
		update,
		siteInfo,
		siteInfoLoading,
		// debug
	]);
	return siteInfo;
}

export function usePageNavigation() {
	const [{pages, pagesLoading}, update] = useGlobalContext();
	const destroyedRef = useRef(false);
	useEffect(() => {
		return () => {
			destroyedRef.current = true;
		};
	}, []);
	useEffect(() => {
		if (!pages && !pagesLoading) {
			update({pagesLoading: true});
			fetchQuery({
				text: rightNavQuery,
			}).then(({data}) => {
				!destroyedRef.current &&
					update({
						pages: data.pages.items,
					});
			});
		}
	}, [update, pages, pagesLoading]);
	return pages;
}

export function useLeftNavigation() {
	const [{leftNav, leftNavLoading}, update] = useGlobalContext();
	const destroyedRef = useRef(false);
	useEffect(() => {
		return () => {
			destroyedRef.current = true;
		};
	}, []);
	useEffect(() => {
		if (!leftNav && !leftNavLoading) {
			update({leftNavLoading: true});
			fetchQuery({
				text: leftNavQuery,
			}).then(({data}) => {
				var leftNavInfo_items = [];
				data.side_nav_content.items.forEach((element) =>
					leftNavInfo_items.push(LeftNavInfo(element))
				);
				leftNavInfo_items = leftNavInfo_items.sort(compareLeftNavItems);

				!destroyedRef.current && update({leftNav: leftNavInfo_items});
			});
		}
	}, [update, leftNav, leftNavLoading]);
	return leftNav;
}

export function useCategoryOptions() {
	const [{categoryOptions, categoryOptionsLoading}, update] =
		useGlobalContext();
	const destroyedRef = useRef(false);
	useEffect(() => {
		return () => {
			destroyedRef.current = true;
		};
	}, []);
	useEffect(() => {
		if (!categoryOptions && !categoryOptionsLoading) {
			update({categoryOptionsLoading: true});
			fetchQuery(
				{
					text: categoriesQuery,
				},
				{}
			).then(({data}) => {
				var opts = [];
				data.component_category.items.forEach((element) =>
					opts.push({
						title: element.name,
						key: element.key,
					})
				);

				!destroyedRef.current &&
					update({
						categoryOptions: opts,
					});
			});
		}
	}, [update, categoryOptions, categoryOptionsLoading]);
	return categoryOptions;
}

export function useIngredientOptions() {
	const [{ingredientOptions, ingredientOptionsLoading}, update] =
		useGlobalContext();
	const destroyedRef = useRef(false);
	useEffect(() => {
		return () => {
			destroyedRef.current = true;
		};
	}, []);
	useEffect(() => {
		if (!ingredientOptions && !ingredientOptionsLoading) {
			update({ingredientOptionsLoading: true});
			fetchQuery(
				{
					text: ingredientsQuery,
				},
				{}
			).then(({data}) => {
				var opts = [];
				data.component_ingredient.items.forEach((element) =>
					opts.push({
						title: element.name,
						key: element.key,
					})
				);

				!destroyedRef.current &&
					update({
						ingredientOptions: opts,
					});
			});
		}
	}, [update, ingredientOptions, ingredientOptionsLoading]);
	return ingredientOptions;
}

function constructQueryCacheKey(qi, fr, sz, db) {
	return JSON.stringify({
		queryInfo: qi,
		from: fr,
		size: sz,
		debug: db,
	});
}

function constructQuery(qry, from, size, debug) {
	const query = createQuery('elasticsearch');
	query.query = {
		_source: [
			// mandatory for crafter API
			'objectId',
			'localId',
			'file-name',
			'placeInNav',
			'internal-name',
			'content-type',
			'createdDate_dt',
			'lastModifiedDate_dt',
			'disabled',

			// all summary items
			'title_s',
			'subtitle_s',
			'snippet_t',
			'categories_o',
			'ingredients_o',
			'tags_o',
			'cardImage_s',
			'isFeatured_b',

			// handy to know what products are attached to the content item sometimes
			// 'products_o',

			// type-specific additions
			'datePublished_dt',
			'pdf_o',
			'seminarDate_dt',
			'congressDate_dt',
			'headshot_s',
			'name_s',
			'position_s',
			'highlight_s',
			'readTime_s',
			'landingPage_o',
			'titleLogo_s',
			'congressBackground_s',
			'seminarBackground_s',
			'year_i',
			'issueNo_i',
			'duration_s',
			'podcastHost_s',
			'articleTypeSelector_s',
			'articleContentSelector_s',
			'slug_s',
			'registrationsOpen_b',
			'webinarDate_dt',
			'productImage_s',
			'productinformation_t',
			'webinarNumber_s',
			'relatedWebinars_o',
		],
		query: {
			bool: {
				must: [],
				must_not: [],
				should: [
					{
						term: {
							isFeatured_b: {
								value: true,
								boost: 5.0,
							},
						},
					},
				],
			},
		},
		sort: [],
		from: from,
		size: size,
		explain: debug,
	};

	// dynamically populate the query

	// first suppress hidden items
	query.params.query.bool.must_not.push({
		bool: {
			should: {
				match: {
					'tags_o.item.component.internal-name': 'HIDDEN',
				},
			},
		},
	});

	if (qry.tagKeys && qry.tagKeys.length > 0) {
		query.params.query.bool.must.push({
			bool: {
				should: qry.tagKeys?.map((id) => ({
					multi_match: {
						query: id,
						fields: [
							'tags_o.item.key',
							'products_o.item.component.tags_o.item.key',
						],
					},
				})),
			},
		});
	}

	if (qry.ingredientKeys && qry.ingredientKeys.length > 0) {
		query.params.query.bool.must.push({
			bool: {
				should: qry.ingredientKeys?.map((id) => ({
					match: {'ingredients_o.item.key': id},
				})),
			},
		});
	}

	if (qry.categoryKeys && qry.categoryKeys.length > 0) {
		query.params.query.bool.must.push({
			bool: {
				should: qry.categoryKeys?.map((id) => ({
					match: {'categories_o.item.key': id},
				})),
			},
		});
	}

	if (qry.contentTypes && qry.contentTypes.length > 0) {
		query.params.query.bool.must.push({
			bool: {
				should: qry.contentTypes?.map((id) => ({
					match: {'content-type': id},
				})),
			},
		});
	}

	if (antisearchterms.includes(qry.searchTerm)) {
		query.params.query.bool.must_not.push({
			bool: {
				should: qry.contentTypes.map(() => ({
					match: {'content-type': '/component/metaproduct'},
				})),
			},
		});
	}

	if (qry.searchTerm) {
		query.params.query.bool.should.push({
			multi_match: {
				query: qry.searchTerm.toLowerCase(),
				type: 'most_fields',
				fields: [
					// BOOST FIELDS
					'title_s^3.0',
					'title_t^3.0',
					'products_o.item.component.taxItemName_s^3.0',
					'products_o.item.component.taxItemName_t^3.0',
					'categories_o.item.component.taxItemName_s^2.5',
					'categories_o.item.component.taxItemName_t^2.5',
					'tags_o.item.component.internal-name^2.5',
					'products_o.item.component.tags_o.item.component.internal-name^2.5',
					'snippet_t^2.0',

					// ALL OTHER FIELDS TO MATCH
					'subtitle_s',
					'subtitle_t',
					'body_html',
					'description_html',
					'summary_html',
					'name_s',
					'name_t',
					'referenceBlock_html',
					'protocolBody_html',
					'quote_html',
					'city_s',
					'city_t',
					'address1_s',
					'address1_t',
					'address2_s',
					'address2_t',
					'state_s',
					'postalCode_s',
					'country_s',
					'country_t',
					'position_s',
					'position_t',
					'catchphrase_s',
					'catchphrase_t',
					'footerBody_html',
					'shortNavTitle_s',
					'shortNavTitle_t',
					'highlight_s',
					'highlight_t',
				],
				fuzziness: 1,
			},
		});
	}

	// sort info
	if (qry.searchTerm) {
		// console.log("Search term specified.  Overriding sort criteria!");
		query.params.sort.push({_score: {order: 'desc'}});
	} else {
		// console.log("Incoming sort info", qry.sortInfo)
		if (qry.sortInfo) {
			// console.log("Applying sort info")
			query.params.sort = qry.sortInfo;
		} else {
			// console.log("Using default sort info");
			// default sort order - _score first then title_s in case there are ties.
			query.params.sort.push({_score: {order: 'desc'}});
			query.params.sort.push({title_s: {order: 'asc'}});
		}
	}

	return query;
}

export function useElasticSearch(qry, from = 0, size = 6) {
	const [elasticQueryCache, updateEQCache] = useGlobalContext();
	const [elasticresult, setElasticresult] = useState(neverResource);
	const [{debug}] = useGlobalContext();

	useEffect(() => {
		const key = constructQueryCacheKey(qry, from, size, debug);
		const cachedResult = elasticQueryCache[key];
		if (cachedResult !== undefined && cachedResult !== null) {
			// console.log("Returning from cache: "+JSON.stringify(cachedResult))
			setElasticresult(cachedResult);
		} else {
			// console.log("New query: " + JSON.stringify(key))
			const query = constructQuery(qry, from, size, debug);
			const resource = createResource(() =>
				search(query)
					.pipe(
						map(({hits, ...rest}) => {
							// console.log("useElasticSearch ran query ", query.params)
							// console.log("                 and returned results ", hits);
							// console.log("                 and returned rest ", rest);
							const parsedHits = hits.map(
								({_source, _score, _explanation, _id}) =>
									parseElasticResult(_source, _score, _explanation, _id)
							);
							return {...rest, hits: parsedHits};
						})
					)
					.toPromise()
			);
			elasticQueryCache[key] = resource;
			updateEQCache(elasticQueryCache);
			setElasticresult(resource);
		}
	}, [qry, from, size, debug, elasticQueryCache, updateEQCache]);
	return elasticresult;
}
