import React, { useState } from 'react';

import { NavLink } from 'react-router-dom';

import { motion } from 'framer-motion';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import HomeRoundedIcon from '@material-ui/icons/HomeRounded';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { usePageNavigation } from '../crafter_site_core/hooks';

import Icon from '../utils/icon';

const useStyles = makeStyles((theme) => ({
  drawerroot: {
    zIndex: 1302 + ' !important',
  },
  list: {
    width: 300,
  },
  fullList: {
    width: 'auto',
  },
  header: {
    padding: theme.spacing(3),
  },
  closebuttoncontainer: {
    margin: theme.spacing(1),
    '& button': {
      float: 'right',
    },
  },
  button: {
    backgroundColor: theme.palette.secondary.main,
    position: 'fixed',
    top: theme.spacing(2),
    right: theme.spacing(3),
    borderRadius: 100,
    zIndex: 99,
    [theme.breakpoints.down('md')]: {
      top: theme.spacing(1.5),
      right: theme.spacing(1.5),
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  buttonsmall: {
    backgroundColor: theme.palette.secondary.main,
    position: 'fixed',
    top: theme.spacing(1.5),
    right: theme.spacing(1.5),
    borderRadius: 100,
    zIndex: 99,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  homebuttonsmall: {
    backgroundColor: theme.palette.secondary.main,
    position: 'fixed',
    top: theme.spacing(1.5),
    left: theme.spacing(1.5),
    borderRadius: 100,
    zIndex: 99,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  accountaccess: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    backgroundColor: theme.palette.primary.darkest,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
    padding: theme.spacing(3, 2),
    '& a': {
      color: theme.palette.secondary.main,
    },
  },
  signinbutton: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  menuicon: {
    color: theme.palette.common.white,
  },
}));

export default function PageMenu() {
  const nav = usePageNavigation();
  const classes = useStyles();
  const theme = useTheme();
  const [state, setState] = useState({
    right: false,
  });
  const toggleDrawer = (side, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [side]: open });
  };
  const sideList = (side) => (
    <div
      className={classes.list}
      role='presentation'
      onClick={toggleDrawer(side, false)}
      onKeyDown={toggleDrawer(side, false)}
    >
      <div className={classes.closebuttoncontainer}>
        <IconButton aria-label='close' color='secondary'>
          <ArrowForwardIcon />
        </IconButton>
      </div>
      <Typography variant='h4' className={classes.header}>
        Menu
      </Typography>
      <List>
        {nav
          ?.sort(({ orderDefault_f: a }, { orderDefault_f: b }) =>
            a < b ? -1 : a > b ? 1 : 0
          )
          .map((page) => (
            <NavLink to={page.url} key={page.url}>
              <ListItem button>
                <ListItemIcon>
                  {page.pageIcon_s != null ? (
                    <Icon
                      name={page.pageIcon_s}
                      widthsize={32}
                      heightsize={32}
                      color={theme.palette.secondary.main}
                    />
                  ) : null}
                </ListItemIcon>
                <ListItemText primary={page.navLabel} />
                <ListItemSecondaryAction>
                  <IconButton edge='end' aria-label='goto' color='secondary'>
                    <KeyboardArrowRightIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            </NavLink>
          ))}
        {/* <ListItem divider />
        <NavLink to='/search'>
          <ListItem button>
            <ListItemIcon>
              <BookmarkBorderOutlinedIcon color='secondary' />
            </ListItemIcon>
            <ListItemText primary='Search' />
            <ListItemSecondaryAction>
              <IconButton edge='end' aria-label='goto' color='secondary'>
                <KeyboardArrowRightIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        </NavLink> */}
        {/* <NavLink to='/faqs'>
          <ListItem button>
            <ListItemIcon>
              <Icon
                name='faq-menu'
                widthsize={32}
                heightsize={32}
                color={theme.palette.primary.highlight}
              />
            </ListItemIcon>
            <ListItemText primary='FAQs' />
            <ListItemSecondaryAction>
              <IconButton edge='end' aria-label='goto' color='secondary'>
                <KeyboardArrowRightIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        </NavLink> */}
      </List>
      {/* <div className={classes.accountaccess}>
        <Button
          variant='contained'
          color='secondary'
          startIcon={<PersonIcon />}
          className={classes.signinbutton}
        >
          Sign In
        </Button>
        <Typography variant='body2'>
          Don't have an account?{' '}
          <a
            href='https://metagenics.com.au'
            target='_blank'
            rel='noopener noreferrer'
          >
            Create Metagenics Account
          </a>
        </Typography>
      </div> */}
    </div>
  );
  return (
    <>
      <Drawer
        anchor='right'
        open={state.right}
        onClose={toggleDrawer('right', false)}
        className={classes.drawerroot}
      >
        {sideList('right')}
      </Drawer>
      <motion.div
        className={classes.button}
        whileHover={{ scale: 1.1, rotate: 180 }}
      >
        <label htmlFor='icon-button-file'>
          <IconButton component='span' onClick={toggleDrawer('right', true)}>
            <MenuRoundedIcon className={classes.menuicon} />
          </IconButton>
        </label>
      </motion.div>
      <motion.div
        className={classes.buttonsmall}
        whileHover={{ scale: 1.1, rotate: 360 }}
      >
        <NavLink to='/search'>
          <label htmlFor='icon-button-file'>
            <IconButton component='span'>
              <SearchOutlinedIcon className={classes.menuicon} />
            </IconButton>
          </label>
        </NavLink>
      </motion.div>
      <motion.div
        className={classes.homebuttonsmall + ' no-print'}
        whileHover={{ scale: 1.1, rotate: 360 }}
      >
        <NavLink to='/'>
          <label htmlFor='icon-button-file'>
            <IconButton component='span'>
              <HomeRoundedIcon className={classes.menuicon} />
            </IconButton>
          </label>
        </NavLink>
      </motion.div>
    </>
  );
}
