// language=GraphQL
export default `
    fragment f_std_fields on ContentItem {
        guid: objectId
        path: localId
        contentTypeId: content__type
        dateCreated: createdDate_dt
        dateModified: lastModifiedDate_dt
        label: internal__name
    }

    query siteQuery {
        siteinfo: component_level__descriptor {
            total
            items {
                ... on component_level__descriptor {
                    ...f_std_fields
                    siteName_s
                    debugMode_s
                    favicon180x180_s
                    favicon32x32_s
                    favicon16x16_s
                    favicon_s
                    maskIcon_s
                    maskColor_s
                    msAppLogo70x70_s
                    msAppLogo150x150_s
                    msAppLogo310x150_s
                    msAppLogo310x310_s
                    mSAppTileColor_s
                    socialFacebookLink_s
                    shown_b
                    shownAO_b
                    backgroundImage_s
                    backgroundImageAO_s
                    backgroundImageMobile_s
                    backgroundImageMobileAO_s
                    contentType_s
                    contentTypeAO_s
                    calloutTitle_s
                    calloutTitleAO_s
                    buttonText_s
                    buttonTextAO_s
                    link_s
                    linkAO_s
                    mainColour_s
                    mainColourAO_s
                    secondaryColour_s
                    secondaryColourAO_s
                    pageAO_s
                    socialInstagramLink_s
                    socialTwitterLink_s
                    socialLinkedInLink_s
                    socialYouTubeLink_s
                    footerLogo_s
                    footerBody_html
                    copyright_s
                    podcastDefaultCard_s
                    techDataDefaultCard_s
                    blogDefaultCard_s
                    protocolDefaultCard_s
                    tradePresenterDefaultCard_s
                    congressDefaultCard_s
                    patientBrochureDefaultCard_s
                    updateIssueDefaultCard_s
                    seminarDefaultCard_s
                    caseStudyDefaultCard_s
                    infographicDefaultCard_s
                    updateArticleDefaultCard_s
                    webinarDefaultCard_s
                    pdfDefaultCard_s
                    videoDefaultCard_s                    
                    footerNavItems_o {
                        item {
                            key
                            value        
                        }
                    }
                    sitewideDisclaimer_o {
                        item {
                            key
                            component {
                                ... on component_disclaimer {
                                  ...f_std_fields
                                  body_html
                                  body_html_raw
                                }
                            }                           
                        }
                    }
                }
            }
        }
    }

`;
