import React from 'react';
import ContentType from '../../../crafter_site_core/ContentType';
import CircularProgressSpinner from '../../../crafter_site_core/CircularProgressSpinner';

import Box from '@material-ui/core/Box';

export default function (props) {
  const { model } = props;
  return (
    <>
      <Box my={4}>
        {model ? (
          <ContentType model={model} format='HERO' />
        ) : (
          <CircularProgressSpinner technical={'SearchResultHero'} />
        )}
      </Box>
    </>
  );
}
