import React, { useState, Suspense, useRef } from 'react';

import { useElasticSearch } from '../../../crafter_site_core/hooks';
import ContentType from '../../../crafter_site_core/ContentType';
import CircularProgressSpinner from '../../../crafter_site_core/CircularProgressSpinner';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Pagination from '@material-ui/lab/Pagination';
import Box from '@material-ui/core/Box';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ExpandLessRoundedIcon from '@material-ui/icons/ExpandLessRounded';
// import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";

import { labelPlural } from '../../../crafter_site_core/contentTypeMap';
import { useGlobalContext } from '../../../crafter_site_core/context';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    borderWidth: '3px !important'
  },
  row: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  col: {
    [theme.breakpoints.up('1776')]: {
      flex: '0 1 16.66%'
    },
    [theme.breakpoints.down('1776')]: {
      flex: '0 1 20%'
    },
    [theme.breakpoints.down('1336')]: {
      flex: '0 1 25%'
    },
    [theme.breakpoints.down('1025')]: {
      flex: '0 1 33%'
    },
    [theme.breakpoints.down('641')]: {
      flex: '0 1 50%'
    },
    [theme.breakpoints.down('378')]: {
      flex: '0 1 100%'
    },
    height: '100%',
    padding: '0.5rem 0.25rem 0'
  },
  debugScoreExplanation: {
    color: 'black',
    background: '#EEE',
    fontSize: 'smaller',
    overflowWrap: 'breakWord',
    padding: '14px',
    borderRadius: '15px',
    display: 'none'
  },
  debugScoreExplanationTH: {
    textAlign: 'right'
  },
  searchAmounts: {
    [theme.breakpoints.down('641')]: {
      display: 'none'
    }
  }
}));

export default function SearchResultIntermediary(props) {
  const locationRef = useRef(null);
  const { qry, from, size } = props;
  const [pageSize, setPageSize] = useState(size);
  const [start, setStart] = useState(from);
  const [loadingMore, setLoadingMore] = useState(false);
  const sr = useElasticSearch(qry, start, pageSize);
  const type = qry.contentTypes.length === 1 ? qry.contentTypes[0] : null;

  const resetLocation = () => {
    window.scrollTo({
      behavior: 'smooth',
      top: locationRef.current.offsetTop - 105
    });
  };

  function loadMore() {
    // we don't want to just load the next page, we need to load the prev page + the next page.
    // later on we will be able to append newly loaded results, but for this interim mode,
    // we need to load everything.
    const newSize = pageSize * 3 + size;
    setPageSize(newSize);
    // setStart(start + size);
    setLoadingMore(true);
    resetLocation();
  }

  function loadAll(contentAmount) {
    const newSize = contentAmount;
    setPageSize(newSize);
    setLoadingMore(true);
    resetLocation();
  }

  function unloadResults() {
    setStart(0);
    setPageSize(size);
    setLoadingMore(false);
    resetLocation();
  }

  if (!loadingMore && pageSize !== size) {
    setPageSize(size);
  }

  function nextItems(page) {
    // let startSpot = start + size;
    let startSpot = pageSize * page - pageSize;
    setStart(startSpot);
    resetLocation();
  }

  function prevItems(page) {
    // let startSpot = start - size;
    let startSpot = pageSize * page - pageSize;
    setStart(startSpot);
    resetLocation();
  }

  return (
    <>
      <div ref={locationRef} />
      <Suspense
        fallback={
          <CircularProgressSpinner
            message={'Loading content...'}
            technical={'SearchResultIntermediary'}
            screenHeight={false}
          />
        }
      >
        <ElasticResultRenderer
          type={type}
          resource={sr}
          load={loadMore}
          unload={unloadResults}
          next={nextItems}
          prev={prevItems}
          size={size}
          pagesize={pageSize}
          start={start}
          reference={locationRef}
          loadall={loadAll}
          resetloc={resetLocation}
        />
      </Suspense>
    </>
  );
}

/**
 * This component has to exist as a separate component from the suspense block, in order for the read() to work.
 * @param resource
 * @returns {*}
 * @constructor
 */
function ElasticResultRenderer({
  type,
  resource,
  load,
  unload,
  start,
  size,
  pagesize,
  next,
  prev,
  loadall,
  resetloc
}) {
  const classes = useStyles();
  const sr = resource.read();
  const [currentPage, setCurrentPage] = useState(1);
  const handlePageUpdate = (event, value) => {
    if (value < currentPage) {
      prev(value);
    } else if (value > currentPage) {
      next(value);
    }
    setCurrentPage(value);
    resetloc();
  };
  const handleCurrentPage = () => {
    setCurrentPage(1);
  };
  const runCollapse = () => {
    unload();
    handleCurrentPage();
  };
  return (
    <Box my={1}>
      {sr.hits !== null && sr.hits.length > 0 ? (
        <>
          <Box
            mb={1}
            display='flex'
            alignItems='flex-end'
            // justifyContent='space-between'
          >
            <Typography variant='h4'>{labelPlural()[type]}</Typography>
            <Box ml={4} className={classes.searchAmounts}>
              <Typography>
                Showing: {start + 1} to{' '}
                {start + pagesize < sr.total.value ? start + pagesize : sr.total.value}
                {/* Page Size: {pagesize} */} | Total Items: {sr.total.value}
                {/* | Hits Length: {' ' + sr.hits.length} */}
              </Typography>
            </Box>
          </Box>
          <Box mb={2}>
            <Box className={classes.row}>
              {sr.hits.map((hit, index) => (
                <Box className={classes.col} key={index}>
                  <ContentType model={hit} format='CARD' />
                  <ScoreDumper hit={hit} />
                </Box>
              ))}
            </Box>
          </Box>

          <Box
            mb={2}
            width={1}
            display='flex'
            justifyContent='flex-end'
            height={56}
          >
            {/* {sr.hits.length === size ? (
              <Button
                variant='contained'
                color='secondary'
                className={classes.button}
                endIcon={<ExpandMoreRoundedIcon />}
                onClick={() => loadall(sr.total.value)}
              >
                Show All
              </Button>
            ) : null} */}
            {sr.hits.length === size ? (
              <Button
                variant='outlined'
                color='secondary'
                className={classes.button}
                endIcon={<AddCircleOutlineIcon color='secondary' />}
                onClick={load}
              >
                Show More
              </Button>
            ) : null}
          </Box>

          {pagesize > size ? (
            <Box
              mb={2}
              width={1}
              display='flex'
              justifyContent='flex-end'
              alignItems='center'
            >
              {/* <Box>
                <Typography>
                  Showing: {start} to{" "}
                  {start + pagesize < sr.total.value ? start + pagesize : sr.total.value}
                  {/* Page Size: {pagesize} */}{' '}
              {/* | Total Items: {sr.total.value} */}
              {/* | Hits Length: {' ' + sr.hits.length} */}
              {/* </Typography> */}
              {/* </Box> */}
              <Box display='flex' alignItems='center'>
                <Button
                  variant='outlined'
                  color='secondary'
                  className={classes.button}
                  startIcon={<ExpandLessRoundedIcon color='secondary' />}
                  onClick={runCollapse}
                >
                  Collapse
                </Button>
                {pagesize !== sr.total.value ? (
                  <Pagination
                    count={Math.floor(sr.total.value / pagesize) + 1}
                    color='secondary'
                    page={currentPage}
                    onChange={handlePageUpdate}
                    siblingCount={1}
                  />
                ) : null}
              </Box>
            </Box>
          ) : null}
        </>
      ) : (
        <div />
      )}
    </Box>
  );
}

function ScoreDumper({ hit }) {
  const classes = useStyles();
  const [{ debug }] = useGlobalContext();
  if (debug) {
    return (
      <>
        <p
          onMouseDown={(e) => {
            document.getElementById('explain:score:' + hit.id).style.display =
              'block';
          }}
          onMouseUp={(e) => {
            document.getElementById('explain:score:' + hit.id).style.display =
              'none';
          }}
          id={'score:' + hit.id}
          style={{ textAlign: 'center', fontSize: 'small', color: 'grey' }}
        >
          SCORE: {hit.score} (CLICK FOR DETAILS)
        </p>
        <div
          id={'explain:score:' + hit.id}
          className={classes.debugScoreExplanation}
        >
          <div>
            <u>Search Result Score Explanation</u>
          </div>
          <table>
            <tbody>
              {hit.explanation.map((ex, index) => (
                <tr key={index}>
                  <th className={classes.debugScoreExplanationTH}>
                    {ex.score}
                  </th>
                  <td>{ex.explanation}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </>
    );
  } else {
    return null;
  }
}
