import React from "react";
import SearchBarLg from "./SearchBar/SearchBarLg";
// import SearchBarCombined from './SearchBar/SearchBarCombined';
// import SearchBarSingleSelect from './SearchBar/SearchBarSingleSelect';
import SearchBarMd from "./SearchBar/SearchBarMd";
// import SearchBarSm from './SearchBar/SearchBarSm';
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
  searchresultscontainer: {
    marginTop: 100,
    [theme.breakpoints.down("sm")]: {
      marginTop: 80
    }
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default
  },
  searchbarlg: {
    position: "sticky",
    top: -12,
    backgroundColor: "#fafafa",
    zIndex: 1,
    padding: "1px 0",
    boxShadow: "0px 5px 16px -8px rgba(107,152,217,0.35)",
    [theme.breakpoints.down("md")]: {
      display: "none"
    }
  },
  searchbarmd: {
    [theme.breakpoints.up("lg")]: {
      display: "none"
    }
    // [theme.breakpoints.down('sm')]: {
    //   display: 'none',
    // },
  }
  // searchbarsm: {
  //   [theme.breakpoints.up('md')]: {
  //     display: 'none',
  //   },
  // },
}));

export default function (props) {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.searchbarlg}>
        <SearchBarLg />
        {/* <SearchBarCombined /> */}
        {/* <SearchBarSingleSelect /> */}
      </Box>
      <Box className={classes.searchbarmd}>
        <SearchBarMd />
      </Box>
      {/*<Box className={classes.searchbarsm}>*/}
      {/*  <SearchBarSm />*/}
      {/*</Box>*/}
    </>
  );
}
