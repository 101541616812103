import React from "react";
import Icons from "../images/icons.svg";
import PropTypes from "prop-types";

const Icon = ({ name, color, widthsize, heightsize }) => (
  <svg
    className={`icon icon-${name}`}
    fill={color}
    width={widthsize}
    height={heightsize}
  >
    <use xlinkHref={`${Icons}#icon-${name}`} />
  </svg>
);

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
  size: PropTypes.number
};

export default Icon;
