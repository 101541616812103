import React, { useEffect } from 'react';
import UnderConstruction from './UnderConstruction';
import IENotice from '../common/IENotice';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { drawerWidthConst } from '../utils/constants';
import Footer from './Footer';
import { useGlobalContext } from '../crafter_site_core/context';
import { useIceAllowed } from '../crafter_site_core/IceSupport';
import { reportNavigation } from '@craftercms/ice';

const drawerWidth = drawerWidthConst;
const isIE = false || !!document.documentMode;

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const useStyles = makeStyles((theme) => ({
  pageMargin: {
    marginLeft: drawerWidth,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0
    }
  }
}));

export default function Layout({ children }) {
  const classes = useStyles();
  const [{ debug }] = useGlobalContext();

  const trackForIce = useIceAllowed();
  const navurl = window.location.pathname;
  useEffect(() => {
    if (trackForIce) {
      reportNavigation(navurl);
    }
  }, [navurl, trackForIce]);

  return (
    <>
      <ScrollToTop />
      <div className={classes.pageMargin}>
        {debug ? <UnderConstruction /> : null}
        {isIE ? <IENotice /> : null}
        {children}
        <Footer />
      </div>
    </>
  );
}
