import React from 'react';
import ContentType from '../../../crafter_site_core/ContentType';
import CircularProgressSpinner from '../../../crafter_site_core/CircularProgressSpinner';

export default function (props) {
  const { model, count } = props;
  return (
    <>
      {model ? (
        <ContentType model={model} format={count === 1? 'HERO' : 'FEATURED'} />
      ) : (
        <CircularProgressSpinner technical={'Search result Featured'} />
      )}
    </>
  );
}
