import React from 'react';
import FourOhFour from '../../common/404';

export function NotDeveloped() {
  return (
    <section>
      The page you've selected needs to be created by the site developers.
    </section>
  );
}


export function InvalidData(props) {
  console.log("The component does not have enough data to be renderable: "+JSON.stringify(props, null, 2))
  return (
    <section>
      The page you've selected contains something that can't be shown.
    </section>
  );
}

export function NotFoundPropsNull() {
  console.log("The component cannot be rendered because the input props are null")
  return <FourOhFour detail="pn" />;
}
export function NotFoundPropsUndefined() {
  console.log("The component cannot be rendered because the input props are undefined")
  return <FourOhFour detail="pu" />;
}
export function NotFoundModelNull(props) {
  console.log("The component cannot be rendered because its props are defined but the model is null: "+JSON.stringify(props, null, 2))
  return <FourOhFour detail="mn" />;
}
export function NotFoundModelUndefined(props) {
  console.log("The component cannot be rendered because its props are defined and the the model is undefined: "+JSON.stringify(props, null, 2))
  return <FourOhFour detail="mu" />;
}
